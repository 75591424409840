/* adds some margin below the link sets  */
.mega-navbar .dropdown-menu div[class*="col"] {
    margin-bottom: 1rem;
}

.mega-navbar .dropdown-menu {
    border: none;
    background-color: #fff !important;
    box-shadow: 0 3px 13px rgba(9, 82, 145, 0.1);
    h1 {
        font-size: 20px;
        color: #000;
        margin: 20px;
    }
    .trading-column {
        h2 {
            font-size: 18px;
            color: #f39200;
            margin: 0 20px;
        }
    }
    .manufacturing-column {
        h2 {
            font-size: 18px;
            color: #003d72;
            margin: 0 20px;
        }
    }
    .contracting-column {
        h2 {
            font-size: 18px;
            color: #c10a25;
            margin: 0 20px;
        }
    }
    .services-column {
        h2 {
            font-size: 18px;
            color: #1aa1ed;
            margin: 0 20px;
        }
    }
    ul {
        margin-top: 20px;

        li {
            a {
                font-size: 14px;
                color: #000;
                padding: 4px !important;
                .fa-chevron-right {
                    color: #f39200;
                }
                &:hover {
                    color: #003d72;
                }
            }
        }
    }
    .nav-hover-images {
        img {
            position: absolute;
            inset: 0px;
            box-sizing: border-box;
            padding: 0px;
            border: none;
            margin: auto;
            display: block;
            width: 0px;
            height: 0px;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: right center;
        }
    }
}

/* breakpoint and up - mega dropdown styles */
@media screen and (min-width: 992px) {
    /* remove the padding from the mega-navbar so the dropdown hover state is not broken */
    .mega-navbar {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
    .mega-navbar .nav-item {
        margin: 0 20px;
    }

    /* makes the dropdown full width  */
    .mega-navbar .dropdown {
        position: static;
    }

    .mega-navbar .dropdown-menu {
        width: 100%;
        left: 76px;
        right: 0;
        top: 45px;

        display: block;
        visibility: hidden;
        opacity: 0;
        transition:
            visibility 0s,
            opacity 0.3s linear;
    }
    /* shows the dropdown menu on hover */
    .mega-navbar .dropdown:hover .dropdown-menu,
    .mega-navbar .dropdown .dropdown-menu:hover {
        display: block;
        visibility: visible;
        opacity: 1;
        transition:
            visibility 0s,
            opacity 0.3s linear;
    }
    .mega-navbar .dropdown-menu {
        background-color: #fff;
    }
}
.mobileScreen {
    h1 {
        font-size: 17px;
        color: #fff;
        margin: 20px 0;
    }
    h2 {
        font-size: 16px;
        color: #fff;
    }
    ul {
        list-style: unset;
        padding-left: 0;
        li {
            a {
                font-size: 18px;
                color: #000;
                padding: 4px !important;
                .fa-chevron-right {
                    color: #f39200;
                }
                &:hover {
                    color: #003d72;
                }
            }
            .nav-link {
                font-size: 16px !important;
            }
            .subsidiaries-btn {
                font-size: 18px;
                padding: 4px !important;
                color: white;
                transition: color 0.3sease;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                img {
                    width: 15px;
                }
                &:focus {
                    box-shadow: unset;
                }
            }
        }
    }
}
