/*mobile view*/

@media (max-width: 600px) {    
    .searchinputMobile {
        width: 100%;
        max-width: 700px;
        border-radius: 3px;
        border: none;
        margin: 27px auto auto;
        display: block !important;
    }

    .buybutton {
        margin: auto auto 60px 50px !important;
    }

    .carousel-caption h2 {
        margin-left: 15px;
    }
    .carousel-indicators {
        bottom: 5px !important;
        justify-content: center !important;
    }

    .carousel-control-prev-icon {
        margin-left: 50px !important;
        padding: 20px !important;
    }

    .carousel-control-next-icon {
        margin-right: 50px !important;
        padding: 20px !important;
    }

    .letter {
        width: 11.11% !important;
    }
    .carousel-caption p {
        max-width: 234px !important;
        font-size: 16px !important;
    }
    .carousel-caption p,
    .carousel-caption .caption-btn {
        margin-left: 15px !important;
    }

    .footer-sec .footer-wrapper .mainfooter {
        padding: 50px 15px !important;
    }

    .our_products-sec.contactus .contact-form .submit-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .about-us .about-right p {
        margin: 0;
    }
    .about-us .about-right .read-more {
        margin: 25px 0;
    }
    .reference-detail
        .related-product
        .pdt-supplied-carousel
        .react-multi-carousel-list
        .react-multi-carousel-item.react-multi-carousel-item--active {
        margin-right: -62px;
    }
    .reference-detail
        .related-product
        .pdt-supplied-carousel
        .react-multi-carousel-list
        .react-multi-carousel-item--active
        .carousel__item {
        width: 72% !important;
    }
    .reference-detail
        .related-product
        .pdt-supplied-carousel
        .react-multi-carousel-list
        .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
        opacity: 0.61;
    }

    .reference-detail
        .related-product
        .pdt-supplied-carousel
        .react-multi-carousel-list
        .react-multi-carousel-item:last-child.react-multi-carousel-item--active
        .carousel__item {
        width: 100% !important;
    }
    .reference-detail
        .related-product
        .pdt-supplied-carousel
        .react-multi-carousel-list
        .react-multi-carousel-item:last-child.react-multi-carousel-item--active {
        margin: 0 !important;
    }
    .Careers .our_products-sec.learning .sec-title {
        font-size: 30px;
    }
    .career-detail .details .our_products-sec.careerDetail .sec-title {
        font-size: 27px;
        white-space: nowrap;
    }
    .Careers .our_products-sec.benefits .counter-field {
        margin-top: 0px;
    }
}

@media (min-width: 1445px) and (max-width: 2000px) {
    .timeline .arrows .arrow__next {
        right: calc(3.5% + 1px);
    }

    .timeline .arrows .arrow__prev {
        left: calc(3.5% + 1px);
    }

    .carousel-item img {
        height: 100%;
    }
    .carousel-caption {
        bottom: 20rem;
    }
    .HomeSubsidiaries_bg_line__OTckB.footer {
        bottom: 330px !important;
    }
    .our-reference .our_products-sec.reference .reference-list .carousel__item .image-box {
        height: 258px;
    }
    .our_products-sec.latestNews .latest-news-sec .news-section .image-box {
        height: 290px;
    }
    .our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow {
        bottom: calc(67% + 14px);
    }
    .product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul li {
        min-width: 0 !important;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .card-wrapper {
        width: 20%;
        max-width: 135px;
    }

    .cardimg {
        width: 100%;
        max-width: 111px;
        height: 100%;
        max-height: 158px;
    }

    .card-wrapper p {
        font-size: 15px;
    }

    section {
        width: 100%;
        max-width: 900px;
    }

    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: 420px !important;
    }

    .timeline {
        overflow-x: auto;
    }

    .timeline ol li:last-child {
        width: calc(100% - 62%);
    }
    .carousel-caption {
        bottom: 8rem;
    }
    .HomeSubsidiaries_bg_line__OTckB.footer {
        bottom: 680px !important;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .card-wrapper {
        width: 20%;
        max-width: 125px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .cardimg {
        width: 100%;
        max-width: 111px;
        height: 100%;
        max-height: 158px;
    }

    .card-wrapper p {
        font-size: 13px;
    }

    .card {
        width: 100%;
        max-width: 850px !important;
    } 

    .products-listing .our_products-sec.productlist .sec-bottom .sec-title {
        text-align: left;
        max-width: 100%;
    }

    .products-listing .our_products-sec.productlist .sec-top-title {
        text-align: left;
    }

    .products-listing .bread-crumbs {
        padding: 20px 15px;
    }

    .products-listing .our_products-sec.products {
        padding: 15px 15px;
    }

    .products-listing .our_products-sec.products .carousel__item img {
        height: 200px;
    }
    .our_products-sec.certification {
        padding: 50px 80px;
    }
    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: 420px !important;
    }

    .timeline {
        overflow-x: auto;
    }

    .timeline ol li:last-child {
        width: calc(100% - 55%);
    }
    .carousel-caption {
        bottom: 5rem;
    }
    .HomeSubsidiaries_bg_line__OTckB.footer {
        display: none;
    }
    .product-detail-sec .product-detail-right .pdt-detail-title .right-sec {
        position: unset;
        margin-top: 20px;
    }
}

@media (min-width: 576px) and (max-width: 600px) {
    .card-wrapper {
        width: 100%;
        max-width: 140px;
        min-width: 130px;
    }

    .counter-sec .counter-field .counter-number {
        margin-left: 0;
    }
    .carousel-caption {
        bottom: 1rem;
    }
    .carousel-item img {
        height: 445px;
        object-fit: cover;
    }
    .download-center .mobile-filter .fixedDiv .nosubmit {
        align-items: center !important;
        justify-content: center;
        padding-left: 42px !important;
        background-position: calc(40% + 1px) !important;
    }
    .our_products-sec.mobile-searchproduct .fixedDiv .filter {
        align-items: center !important;
        justify-content: center;
        padding-left: 26px !important;
        background-position: calc(20% + 1px) !important;
    }
    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: 320px !important;
    }
}

@media (min-width: 475px) and (max-width: 576px) {
    .card-wrapper {
        width: 100%;
        max-width: 150px;
        min-width: 100px;
    }

    .cardimg {
        width: 100%;
        max-width: 177px;
        height: 100%;
        max-height: 252px;
    }

    .card-wrapper p {
        font-size: 15px;
    }

    .card {
        width: 100%;
        max-width: 900px !important;
    }

    .carousel-caption .certificatin-img {
        margin-left: 0;
        margin-bottom: 0;
        position: absolute;
        top: -112px;
        right: calc(15% + 1px);
    }
    .carousel-caption {
        bottom: 1rem;
    }
    .carousel-item img {
        height: 445px;
        object-fit: cover;
    }

    .counter-sec .count-col {
        justify-content: flex-start;
        display: block;
        border-right: 0;
        border-bottom: 1px solid #ffffff1c;
    }

    .counter-sec .count-col:last-child {
        border-bottom: 0;
    }

    .counter-sec .count-col .count-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .counter-sec .knomore-sec {
        display: block;
    }

    .counter-sec .counter-field {
        margin-top: 56px;
    }

    .counter-sec .counter-field .counter-number {
        margin-left: 0;
    }

    .products-listing .our_products-sec.productlist .sec-bottom .sec-title {
        font-size: 28px;
        text-align: left;
        line-height: 34px;
    }

    .products-listing .our_products-sec.productlist .sec-top-title {
        text-align: left;
    }

    .products-listing .bread-crumbs {
        padding: 20px 15px;
    }

    .products-listing .our_products-sec.products {
        padding: 15px 15px;
    }

    .products-listing .our_products-sec.products .carousel__item img {
        height: 220px;
    }
    .download-center .mobile-filter .fixedDiv .nosubmit {
        align-items: center !important;
        justify-content: center;
        padding-left: 42px !important;
        background-position: calc(36% + 1px) !important;
    }
    .our_products-sec.mobile-searchproduct .fixedDiv .filter {
        align-items: center !important;
        justify-content: center;
        padding-left: 26px !important;
        background-position: calc(16% + 1px) !important;
    }
    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: max-content !important;
    }
}

@media (max-width: 475px) {
    .card-wrapper {
        width: 33%;
        max-width: 120px;
        min-width: 100px;
        padding-left: 5px;
    }

    .cardimg {
        width: 100%;
        max-width: 177px;
        height: 100%;
        max-height: 252px;
    }

    .card-wrapper p {
        font-size: 15px;
    }

    .card {
        width: 100%;
        max-width: 900px !important;
    }
    .carousel-caption {
        max-width: unset !important;
        min-width: unset !important;
    }

    .carousel-caption h2 {
        margin-left: 15px;
        font-size: 25px;
        line-height: 32px;
        max-width: 196px !important;
    }

    .carousel-caption .certificatin-img {
        margin-left: 0;
        margin-bottom: 0;
        height: 45px;
        position: absolute;
        top: -112px;
        right: 160px;
    }
    .carousel-caption {
        bottom: 1rem;
    }
    /* .carousel-item img {
        height: 445px;
        object-fit: cover;
    } */
    .our_products-sec.certification .certificates .certificate-box {
        width: auto;
    }

    .counter-sec .count-col {
        justify-content: flex-start;
        display: block;
        border-right: 0;
        border-bottom: 1px solid #ffffff1c;
    }

    .counter-sec .count-col:last-child {
        border-bottom: 0;
    }

    .counter-sec .count-col .count-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .counter-sec .knomore-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .counter-sec .counter-field {
        margin-top: 56px;
    }

    .counter-sec .counter-field .counter-number {
        font-size: 28px;
        margin-left: 0px;
    }

    .products-listing .our_products-sec.productlist .sec-bottom .sec-title {
        font-size: 21px;
        text-align: left;
        line-height: 28px;
    }

    .products-listing .our_products-sec.productlist .sec-top-title {
        text-align: left;
        font-size: 13px;
    }

    .products-listing .bread-crumbs {
        padding: 20px 15px;
    }

    .products-listing .our_products-sec.products {
        padding: 15px 15px;
    }

    .products-listing .our_products-sec.products .carousel__item img {
        height: 140px;
    }

    .products-listing .our_products-sec.products .carousel__caption p {
        font-size: 13px;
    }
    .reference-detail .refer-detail {
        height: 180px;
    }
    .reference-detail .our_products-sec.related-product .sec-title {
        text-align: left !important;
    }
    .download-center .mobile-filter .fixedDiv .nosubmit {
        align-items: center !important;
        justify-content: center;
        padding-left: 42px !important;
        background-position: calc(36% + 1px) !important;
    }
    .our_products-sec.mobile-searchproduct .fixedDiv .filter {
        align-items: center !important;
        justify-content: center;
        padding-left: 26px !important;
        background-position: calc(6% + 1px) !important;
    }
    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: max-content !important;
    }
}

@media (min-width: 800px) and (max-width: 1160px) {
    .header-right {
        margin-left: 400px !important;
    }

    .redeem {
        width: 125px;
    }

    .redeembtn {
        width: 125px !important;
        white-space: pre-line;
        margin-left: 0px !important;
    }

    .skyStoreitem {
        width: 140px;
    }

    .dropbtnlong {
        width: 140px !important;
        white-space: nowrap;
    }

    .springSale {
        width: 124px !important;
    }

    .springSalebtn {
        width: 124px !important;
        white-space: pre-line;
    }

    .vip {
        width: 99px !important;
    }

    .vipbtn {
        width: 99px !important;
        white-space: pre-line;
    }

    .header-bar .nav-bar .parent {
        right: 0 !important;
    }

    .header-bar .logo-wrapper {
        margin-left: 15px;
    }
    .header-bar .nav-bar {
        margin: 0 0 !important;
    }

    .partnership .partnership-right {
        padding: 40px 30px 80px 30px;
    }

    .partnership,
    .partnership img {
        height: 510px;
    }

    .our_products-sec.contactus .contact-info p {
        font-size: 16px;
    }

    .news-and-events .details {
        padding: 0 100px;
    }

    .our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow {
        bottom: calc(66% + 1px);
    }
    .counter-sec .video-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .counter-sec .video-sec video {
        width: auto;
    }
}

@media (max-width: 800px) {
    .carousel-indicators {
        bottom: -12px !important;
        z-index: 50;
    }
    .carousel-item img.w-100 {
        display: none;
    }
    .carousel-item img.mobile-banner {
        display: block !important;
    }
    .card h1 {
        display: none;
    }

    .card {
        margin-top: 320px !important;
    }

    .rent {
        margin-top: 50px !important;
    }

    .mobile-menutitle {
        display: block !important;
    }

    .footer-wrapper .bottom-footer .large-view {
        display: none !important;
    }

    .footer-wrapper .bottom-footer .mobile-footer {
        display: block;
    }

    .mobile-footer .logo-wrapper {
        margin-left: 0;
    }

    .footer-sec .footer-wrapper .footer-desc {
        display: unset !important;
    }

    .footer-sec .regionnav {
        margin-top: 15px;
        flex-direction: column;
    }

    .footer-sec .regionnav .regionlink {
        margin: 0 !important;
        display: none;
    }

    .footer-sec .regionnav .regionlink.copyright {
        display: block;
        width: 100%;
    }

    .footer-sec .regionnav .regionlink.copyright span {
        font-size: 18px;
    }

    .counter-sec {
        height: auto;
        margin-top: 10px;
        padding: 50px 15px;
    }

    .excellence .sector-carouselItem .pl-1 {
        padding-left: 15px !important;
    }

    .excellence .sector-carouselItem .pr-1 {
        padding-right: 15px !important;
    }

    .excellence .carousel__item.left {
        margin-bottom: 8px;
    }
    .excellence .carousel__item.left img {
        height: 175px;
    }
    .our_products-sec {
        padding: 30px 15px;
    }

    .counter-sec .video-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .counter-sec .video-sec video {
        width: auto;
    }

    .our_products-sec .sec-bottom {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .our_products-sec .indicators {
        margin-top: 10px;
    }

    .our_products-sec .sec-bottom-title {
        margin-bottom: 20px;
    }

    .our_products-sec .sec-bottom span {
        position: relative;
        top: 448px;
    }

    .our_products-sec .sec-bottom span a {
        background: #000000;
        padding: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        font-size: 18px;
        font-weight: 400;
        box-shadow: none !important;
        outline: none !important;
        border: none;
        border-radius: 0;
        height: 45px;
        width: 150px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .our_products-sec.our-products .sec-bottom span {
        display: none;
    }

    .our_products-sec.our-products .bottom-more {
        display: block;
    }

    .our_products-sec .product-carouselItem {
        margin: 8px 0;
    }
    .our_products-sec
        .product-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active {
        margin-right: -62px;
    }
    .our_products-sec
        .product-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active
        .carousel__item {
        width: 74% !important;
    }
    .our_products-sec
        .product-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
        opacity: 0.61;
    }
    .our_products-sec
        .product-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active
        .carousel__item {
        width: 100% !important;
    }
    .our_products-sec
        .product-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active {
        margin: 0 !important;
    }
    .our_products-sec .sec-top-title,
    .our_products-sec .sec-title {
        text-align: center;
    }
    .our_products-sec .sec-title {
        margin-bottom: 20px;
    }
    .our_products-sec.sectors .sec-top-title,
    .our_products-sec.sectors .sec-title {
        text-align: left !important;
    }

    .our_products-sec.sectors .sec-bottom span {
        display: none;
    }

    .our_products-sec.sectors .sector-carouselItem {
        margin: 8px 0;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active {
        margin-right: -62px;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active
        .carousel__item {
        width: 74% !important;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
        opacity: 0.61;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active
        .carousel__item {
        width: 100% !important;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active {
        margin: 0 !important;
    }
    .our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .overlay {
        width: 74%;
    }
    .our_products-sec.sectors
        .sector-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active
        .overlay {
        width: 100%;
    }
    .partnership {
        margin-top: 0 !important;
        padding: 15px 15px 50px;
    }

    .partnership .row {
        margin-right: -15px;
    }

    .our_products-sec.sectors .sec-bottom {
        align-items: flex-start;
    }

    .partnership .partnership-right {
        padding: 15px 0px 0px 0px;
        /* position: relative;
        bottom: 230px; */
    }

    .partnership img {
        /* position: relative;
        top: 230px; */
        height: 250px;
    }
    .partnership .image-box {
        height: 100%;
    }
    .partnership .image-b {
        display: none;
    }
    .our_products-sec.excellence .sec-top-title,
    .our_products-sec.excellence .sec-title {
        text-align: left !important;
    }
    .our_products-sec.excellence .sec-bottom {
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
    .our_products-sec.excellence .sec-bottom span {
        display: none;
    }

    .our_products-sec.excellence .bottom-more {
        display: block;
    }

    .our_products-sec.excellence .sec-bottom span a {
        background: #fff;
        border: 2px solid #000000;
        color: #000000;
    }

    .our_products-sec.excellence .sector-carouselItem,
    .our_products-sec.latestNews .latest-news-sec {
        margin: 8px 0;
    }
    .our_products-sec.latestNews .sec-top-title,
    .our_products-sec.latestNews .sec-title {
        text-align: left !important;
    }
    .our_products-sec.latestNews .sec-bottom {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
    .our_products-sec.latestNews .sec-bottom span {
        display: none;
    }

    .our_products-sec.latestNews .latest-news-sec .bottom-more {
        display: block;
    }

    .our_products-sec.contactus {
        padding: 50px 15px;
    }

    .our_products-sec.contactus .sec-top-title,
    .our_products-sec.contactus .sec-title {
        text-align: left;
    }
    /* .our_products-sec.contactus .contact-form .did-floating-label-content.agree{
        display: flex;
    } */
    .Contact-Us .our_products-sec.contact-us {
        background-image: url(../images/contact-banner-Mobile.png) !important;
    }
    .Contact-Us .contact.counter-section {
        height: max-content !important;
    }
    .our_products-sec.contactus .contact-form .did-floating-label-content.agree input[type="checkbox"] {
        margin-top: 24px;
    }
    .our_products-sec.contactus .sec-title {
        max-width: 100%;
    }

    .our_products-sec.contactus .contact-info p {
        font-size: 16px;
    }

    .our_products-sec.contactus .contact-info {
        margin-top: 28px;
    }

    .our_products-sec.contactus .contact-form {
        margin-top: 30px;
    }
    .our_products-sec.certification
        .certificate-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active {
        margin-right: -62px;
    }
    .our_products-sec.certification
        .certificate-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item--active
        .certificate-box {
        width: 74% !important;
    }
    .our_products-sec.certification
        .certificate-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
        opacity: 0.61;
    }
    .our_products-sec.certification
        .certificate-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active
        .certificate-box {
        width: 100% !important;
    }
    .our_products-sec.certification
        .certificate-carouselItem
        .react-multi-carousel-list
        ul
        .react-multi-carousel-item.react-multi-carousel-item:last-child.react-multi-carousel-item--active {
        margin: 0 !important;
    }

    .our_products-sec.certification .certificates .certificate-box {
        width: auto;
    }

    .our_products-sec.certification .sec-top-title,
    .our_products-sec.certification .sec-title {
        text-align: center;
    }

    .our_products-sec.certification .sec-bottom {
        align-items: flex-start;
    }

    .our_products-sec.certification .certificate-carouselItem {
        margin-top: 30px;
    }

    .our_products-sec.certification .see-more {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .products-listing .our_products-sec.productlist {
        background-image: url(../images/product-banner-Mobile.png) !important;
    }
    .products-listing .our_products-sec.productlist .sec-top-title {
        margin-top: 82px;
    }
    .products-list .left-side,
    .products-list .bread-crumbs {
        display: none;
    }

    .products-list .our_products-sec.products .pdt-name-top {
        display: block;
    }
    .products-listing .our_products-sec.productlist {
        height: 250px;
    }
    .products-list .our_products-sec.products {
        padding: 30px 15px !important;
    }
    .products-list .our_products-sec.products .pdt-list-show .category-name {
        display: block;
    }
    .products-list .our_products-sec.products .pdt-list-show .carousel__item .carousel__caption p {
        display: block;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .products-list .our_products-sec.products .pdt-list-show .carousel__item .image-box {
        height: 72px;
    }
    .products-list .our_products-sec.products .pdt-list-show .carousel__item .no-image {
        width: auto;
        margin-top: 0;
    }

    .mobile-filter,
    .Mobile-search-filter {
        display: block;
    }

    .product-detail-sec .our_products-sec.product-detail {
        padding: 10px 15px !important;
    }

    .product-detail-sec .bread-crumbs {
        padding: 30px 15px 10px !important;
    }

    .product-detail-sec .product-detail .display-btn {
        padding: 10px;
        background-color: #00a6ef;
        position: fixed;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
        width: 100%;
        box-shadow: 0px 0px 2px 0px #00000099;
        display: flex;
        height: 55px;
        font-size: 18px;
        z-index: 9999;
    }

    .product-detail-sec .product-detail-right {
        margin: 15px 0 !important;
    }

    .product-detail-sec .product-detail-right .pdt-detail-title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .product-detail-sec .product-detail-right .pdt-detail-title p {
        display: none !important;
    }

    .product-detail-sec .our_products-sec.product-detail .mobile-share-icon {
        display: block;
    }

    .product-detail-sec .related-carouselItem {
        display: none;
    }

    .product-detail-sec .our_products-sec.related-product .product-carouselItem {
        display: block;
    }

    .product-detail-sec .product-detail-right .pdt-features {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .product-detail-sec .our_products-sec.related-product {
        padding: 20px 15px;
    }

    .our_products-sec.related-product .sec-bottom {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .our_products-sec .sec-bottom span {
        margin-left: 28%;
    }

    .product-detail-sec {
        margin-bottom: 30px;
    }

    .product-detail-sec .our_products-sec.related-product .bottom-more {
        display: block;
    }

    .product-detail-sec .our_products-sec.related-product .sec-bottom span {
        display: none;
    }

    .Mobile-request-quote {
        display: block;
    }

    .download-center .our_products-sec.downloadcenter {
        height: 380px;
    }

    .download-center .our_products-sec.downloadcenter .sec-bottom .sec-title {
        font-size: 28px;
        text-align: left;
    }

    .download-center .our_products-sec.downloadcenter .sec-top-title {
        text-align: left;
    }

    .download-center .our_products-sec.searchproduct {
        display: none;
    }

    .download-center .our_products-sec.mobile-searchproduct {
        display: block;
    }

    .our_products-sec.mobile-searchproduct.new-active {
        background-color: #fff !important;
        padding: 15px 15px !important;
    }

    .our_products-sec.mobile-searchproduct.new-active .left-side .product-filter h4 {
        color: #0b2a60 !important;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 20px;
        margin-bottom: 0;
    }

    .our_products-sec.mobile-searchproduct.new-active .left-side .product-filter .type-text h5 {
        color: #000000 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
    }

    .our_products-sec.mobile-searchproduct.new-active .left-side .product-filter ul li label {
        font-size: 16px !important;
    }

    .our_products-sec.mobile-searchproduct.new-active .back-btn {
        font-size: 18px !important;
    }

    .our_products-sec.mobile-searchproduct.new-active .type-box {
        border-bottom: 1px solid #e5e5e5;
        padding: 18px 0;
    }

    .our_products-sec.mobile-searchproduct.new-active .accordion {
        margin-top: 0;
    }

    .our_products-sec.mobile-searchproduct.new-active .product-filter .accordion .accordion-body ul {
        padding-left: 0;
    }

    .our_products-sec.mobile-searchproduct.new-active
        .Filter-filed.Search-field
        .product-filter
        .accordion
        .accordion-item
        .accordion-header
        button {
        font-size: 18px !important;
    }

    .our_products-sec.mobile-searchproduct.new-active
        .Filter-filed.Search-field
        .product-filter
        .accordion
        .accordion-body
        ul {
        padding-left: 8px !important;
    }

    .our_products-sec.mobile-searchproduct.new-active
        .Filter-filed.Search-field
        .product-filter
        .accordion
        .accordion-item
        .accordion-header
        button[aria-expanded="true"] {
        color: #000 !important;
    }

    .product-filter .accordion .accordion-item .show {
        border-bottom: 1px solid #e5e5e5 !important;
    }
    .about-us {
        margin-top: 40px;
    }
    .HomeSubsidiaries_bg_line__OTckB.about {
        display: none;
    }
    .our-reference .our_products-sec.reference .sec-bottom {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .our-reference .our_products-sec.reference .sec-bottom .form-control {
        width: 100%;
    }
    .reference-detail .our_products-sec.ref-carousel {
        padding: 20px 15px;
    }
    .reference-detail .bread-crumbs,
    .reference-detail .our_products-sec.ref-detail {
        padding: 20px 15px;
    }

    .reference-detail .our_products-sec.ref-detail .sec-bottom,
    .reference-detail .our_products-sec.related-product {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .reference-detail .our_products-sec.ref-detail .sec-title,
    .reference-detail .our_products-sec.ref-detail .sec-top-title,
    .reference-detail .our_products-sec.related-product .sec-top-title {
        text-align: left;
    }
    .reference-detail .our_products-sec.ref-detail .sec-title {
        width: auto !important;
        line-height: 48px;
    }
    .reference-detail .detail-right {
        padding: 20px 0px;
    }
    .reference-detail .our_products-sec.related-product {
        padding: 10px 15px !important;
    }
    .reference-detail .refernce-non-carousel,
    .reference-detail .product-carouselItem {
        display: block;
    }

    .reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list,
    .reference-detail .our_products-sec.latestNews .sec-bottom span {
        display: none;
    }

    .reference-detail .our_products-sec.latestNews .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Contact-Us .our_products-sec.contactus,
    .Contact-Us .contact.counter-section {
        padding: 20px 15px !important;
    }

    .Contact-Us .our_products-sec.contactus .sec-title {
        font-size: 38px;
    }

    .Contact-Us .contact.counter-section .our_products-sec p.country-names,
    .Contact-Us .contact.counter-section .our_products-sec .mapview,
    .Contact-Us .contact.counter-section .our_products-sec .contact-location {
        display: none;
    }

    .Contact-Us .contact.counter-section .counter-field .count-box {
        display: flex;
        align-items: center;
    }

    .Contact-Us .contact.counter-section .counter-field .count-box .counter-number {
        width: 120px;
    }

    .Contact-Us .contact.counter-section .our_products-sec .sec-title {
        text-align: left !important;
        max-width: 250px;
    }
    .Contact-Us .contact.counter-section .counter-field {
        margin: 0px 0px;
    }
    .Contact-Us .contact.counter-section .counter-field .count-box {
        margin-bottom: 15px;
    }
    .Contact-Us .contact.counter-section .counter-field .count-box .counter-label {
        max-width: 112px;
    }
    .Contact-Us .contact.counter-section .knomore-sec {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Contact-Us .our_products-sec.our-offices .sec-title {
        text-align: left !important;
    }

    .Contact-Us .our_products-sec.our-offices .address-book .office-location {
        width: 100%;
        margin-bottom: 30px;
    }

    .Contact-Us .our_products-sec.our-offices .address-book .office-address {
        height: auto;
    }

    .Contact-Us .our_products-sec.contact-us .sec-bottom {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .Careers .bread-crumbs {
        padding: 15px;
    }

    .Careers .our_products-sec.careers-sec {
        padding: 20px 15px;
    }

    .Careers .our_products-sec.careers-sec .sec-top-title,
    .Careers .our_products-sec.careers-sec .sec-title {
        text-align: left;
    }

    .Careers .our_products-sec.careers-sec .sec-title,
    .Careers .our_products-sec.careers-sec .sec-bottom-title {
        width: auto;
    }

    .Careers .our_products-sec.careers-sec .all-current-opening {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Careers .our_products-sec.careers-sec .sec-right {
        margin-left: 0;
        margin-top: 20px;
    }
    .Careers .our_products-sec.learning .sec-title {
        text-align: left;
        width: 350px !important;
    }
    .Careers .our_products-sec.careers .sec-bottom {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
    .Careers .our_products-sec.learning .right-sec img {
        float: unset;
    }

    .Careers .our_products-sec.benefits .sec-title {
        width: auto;
        text-align: left;
    }

    .Careers .our_products-sec.benefits .sec-top-title {
        text-align: left;
    }

    .Careers .our_products-sec.benefits {
        padding: 20px 15px;
    }

    .Careers .our_products-sec.benefits .counter-field .count-box {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #ffffff69;
    }

    .Careers .our_products-sec.benefits .counter-field .count-box img,
    .Careers .our_products-sec.benefits .counter-field .count-box .desc-div {
        margin-left: 0;
    }

    .Careers .our_products-sec.careers-sec .sec-right {
        float: left;
        right: 0 !important;
    }

    .Careers .our_products-sec.career-filter .sec-title,
    .Careers .our_products-sec.career-filter .sec-top-title {
        text-align: left;
        width: auto;
    }

    .Careers .our_products-sec.learning .right-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Careers .our_products-sec.career-filter .m-view {
        display: block;
    }
    .Careers .our_products-sec.careers-sec,
    .our_products-sec.learning {
        padding: 20px 15px !important;
    }
    .our_products-sec.learning .sec-title {
        margin-top: 20px !important;
    }
    .Careers .our_products-sec.careers-sec .life-at h4 {
        font-size: 22px !important;
    }
    .Careers .our_products-sec.learning .sec-bottom-title {
        max-width: 100%;
    }
    .Careers .our_products-sec.careers-sec .sec-right {
        align-items: center !important;
        justify-content: center !important;
    }
    .Careers .our_products-sec.career-filter .large-view {
        display: none;
    }
    .Careers .benefits-sec {
        background: #041d48 !important;
    }
    .career-detail .details .our_products-sec.careerDetail .sec-bottom-title {
        font-size: 22px !important;
    }

    .Certification .our_products-sec.certify .sec-bottom {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .Certification .our_products-sec.certify .sec-title {
        text-align: left;
    }

    .our_products-sec.certification {
        padding: 50px 15px !important;
    }
    .login-page {
        height: auto;
        background: transparent;
        display: block !important;
    }

    .login-page .login-sec {
        width: 100%;
        padding: 50px 0;
    }

    .login-page .login-sec .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .login-page .login-sec .left-side h1 {
        width: 100%;
        text-align: center;
        font-size: 36px;
    }

    .login-page .login-sec .left-side p {
        width: 250px;
        text-align: center;
    }

    .login-page .login-sec .login-form .signedin {
        flex-direction: column;
        align-items: flex-start;
    }

    .login-page .login-sec .login-form .signedin .form-check {
        margin-bottom: 15px;
    }

    .login-page .login-sec .login-form .register-link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-page.new-register {
        height: auto;
    }

    .login-page.new-register .login-form {
        margin-top: 5px;
    }

    .news-and-events .our_products-sec.latestNews .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .news-and-events .our_products-sec.latestNews .sec-title {
        text-align: left;
    }

    .news-and-events .bread-crumbs {
        padding: 20px 15px;
    }

    .news-and-events .our_products-sec.latestNews,
    .news-and-events .details .bread-crumbs {
        padding: 20px 15px;
    }

    .news-and-events .details {
        padding: 0;
    }

    .news-and-events .details .our_products-sec.latestNews .sec-title {
        width: 100%;
        text-align: left;
        font-size: 28px;
    }

    .news-and-events .details .our_products-sec.latestNews .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .news-time {
        color: #959595;
        font-size: 15px;
    }

    .news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section p:not(.news-time) {
        font-size: 18px;
    }
    .news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .news-certify {
        float: none !important;
    }
    .news-and-events .our_products-sec.relatedNews .latest-news-sec {
        margin-top: 0;
    }

    .news-and-events .our_products-sec.relatedNews .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .news-and-events .our_products-sec.relatedNews .sec-title {
        text-align: left;
        font-size: 28px;
    }

    .news-and-events .our_products-sec.relatedNews .sec-bottom span {
        display: none;
    }

    .news-and-events .our_products-sec.relatedNews .bottom-more {
        display: block;
    }

    .about-us .our_products-sec {
        padding: 20px 0px;
    }
    .about-us .our_products-sec.mission-vision,
    .about-us .our_products-sec.ourvalues,
    .about-us .our_products-sec.latestNews {
        padding: 20px 15px;
    }
    .about-us .our_products-sec .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .about-us .our_products-sec .sec-title {
        max-width: 100%;
        text-align: left;
    }

    .about-us .our_products-sec .sec-top-title {
        text-align: left;
    }

    .about-us .about-right {
        margin: 0;
    }

    .about-us .about-image img {
        object-fit: contain;
        height: max-content;
        display: none;
    }
    .about-us .about-image .mobile-img {
        display: block !important;
    }

    .about-us .about-image {
        padding: 20px 0;
    }

    .about-us .about-accredit {
        flex-direction: column;
        padding: 20px 0;
        align-items: flex-start;
    }

    .about-us .about-accredit p {
        margin-left: 0;
        margin-top: 20px;
        max-width: 100%;
    }

    .about-us .about-facilty {
        background-image: none;
    }

    .about-us .our_products-sec.facilities {
        background-color: #f8fbfd;
        padding: 50px 15px 0;
    }
    .about-us .our_products-sec.company-info .about-services {
        margin: 0px 0;
    }
    .about-us .our_products-sec.company-info .about-services .service-box .service-logo img {
        height: 50px;
        object-fit: unset;
        margin-left: 10px;
    }
    .about-us .our_products-sec.mission-vision .mission-box {
        height: max-content;
    }

    .about-us .our_products-sec.company-info {
        padding: 20px 15px;
    }

    .about-us .our_products-sec.company-info .sec-title {
        margin-top: 20px;
    }

    .about-us .our_products-sec.company-info .about-services .service-box {
        display: flex;
        flex-direction: column;
    }

    .about-us .our_products-sec.company-info .about-services .service-box .middle {
        position: relative;
        top: unset;
        left: unset;
        opacity: 1;
        transform: none;
        width: 100%;
    }

    .about-us .our_products-sec.company-info .about-services .service-box .text p {
        width: 100%;
    }

    .about-us .our_products-sec.company-info .about-services .service-box .service-logo {
        flex-wrap: wrap;
    }

    .about-us .our_products-sec.company-info .about-services .service-box .service-logo img {
        margin-bottom: 15px;
    }

    .about-us .our_products-sec.company-info .about-services .bottom-more {
        display: none;
    }

    .about-us .our_products-sec.latestNews .sec-bottom span {
        display: none;
    }

    .about-us .our_products-sec.latestNews .latest-news-sec .bottom-more {
        margin: 45px 0;
        display: block;
    }

    #CookiePolicyModal .modal-content .modal-body .bottom-more .button-more {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #CookiePolicyModal .modal-content .modal-body .bottom-more .button-more a {
        width: 100%;
    }

    #CookiePolicyModal .modal-body .accept-btn {
        margin-left: 0;
        margin-top: 15px;
    }

    .policy-sec .our_products-sec.policy {
        padding: 30px 15px;
    }

    .policy-sec .our_products-sec.policy .sec-bottom {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .policy-sec .our_products-sec.policy .sec-bottom .sec-title {
        text-align: left;
    }

    .timeline-mobile {
        display: block;
    }

    .timeline {
        display: none;
    }
    .timeline-mobile {
        margin-top: 30px;
    }
    .timeline-mobile li div p {
        width: 100%;
        white-space: break-spaces;
        line-height: 26px;
    }

    .timeline-mobile li div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .timeline-mobile li time,
    .timeline-mobile li div p {
        text-align: center;
    }

    .timeline-mobile li time {
        display: block;
        font-size: 22px;
        font-weight: 600;
        color: #041d48;
        margin-bottom: 8px;
    }

    .timeline-mobile li div p {
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        font-size: 15px;
        font-weight: 400;
    }

    .timeline-mobile li div img {
        height: 46px;
        object-fit: contain;
        margin-bottom: 10px;
    }
    .timeline-mobile li div img:last-child {
        margin-bottom: 70px;
    }
    .timeline-mobile li {
        list-style: none;
    }
    .timeline-mobile .react-multiple-carousel__arrow {
        background: #00a6ef;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        z-index: 1;
        position: absolute;
        bottom: 0;
    }
    .timeline-mobile .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
        left: calc(30% + 1px);
    }
    .timeline-mobile .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
        right: calc(30% + 1px);
    }
    .timeline-mobile .react-multiple-carousel__arrow::before {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 20px;
        content: "" !important;
        margin-left: -14px;
    }
    .timeline-mobile .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right::before {
        background-image: url(../images/morewhite.svg);
    }
    .timeline-mobile .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before {
        background-image: url(../images/more-left.svg);
    }
    .timeline-mobile .react-multiple-carousel__arrow:hover {
        background: #00a6ef !important;
    }
    .timeline .arrows button {
        position: relative;
    }

    .timeline .arrows .arrow__next {
        margin-left: 20px;
        right: 0;
    }

    .timeline .arrows .arrow__prev {
        left: 0;
    }

    .career-detail .details {
        padding: 0 15px;
    }

    .career-detail .our_products-sec.careerDetail {
        padding: 10px 0 40px;
    }

    .career-detail .bread-crumbs {
        padding: 30px 0px;
    }

    .career-detail .details .our_products-sec.careerDetail .sec-title {
        text-align: left;
    }

    .career-detail .details .our_products-sec.careerDetail .sec-bottom {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .career-detail .details .career-left {
        overflow-y: hidden;
        height: auto;
        overflow-x: hidden;
    }

    .career-detail .details .our_products-sec.careerDetail .location-div {
        margin-left: 0;
        margin-top: 25px;
    }

    .career-detail .details .our_products-sec.careerDetail .contact-form .submit-sec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Careers .Filter-field {
        display: block;
    }
    .footer-sec .footer-wrapper .mainfooter {
        padding: 50px 15px !important;
    }
    .HomeSubsidiaries_bg_line__OTckB.footer {
        max-width: 73.55% !important;
        right: 0% !important;
        bottom: 1650px !important;
        left: 56% !important;
    }
    .HomeSubsidiaries_bg_line__OTckB.career {
        display: none;
    }
    .counter-sec svg {
        float: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-detail-sec .product-detail-right .pdt-detail-title .right-sec {
        position: unset;
        margin-top: 20px;
    }
}

@media (min-width: 320px) and (max-width: 991.98px) {
    .navbar-wrapper {
        display: none;
    }

    .user-menu {
        display: none !important;
    }

    .header-bar {
        height: 61px !important;
    }

    .logo {
        width: 90px;
        height: 61px !important;
    }

    .background .headerWrapper {
        height: 61px;
    }

    .header-inner-wrapper {
        height: 61px;
        width: 100%;
        display: flex !important;
    }

    .header-inner-wrapper .logo-wrapper {
        margin-left: unset !important;
        align-items: center;
        margin-top: 0 !important;
    }

    .logo-wrapper {
        margin-top: -40px;
    }

    #menuToggle {
        display: block;
        /* width: 300px; */
        position: absolute;
        right: 13px;
        z-index: 1;
        padding-top: 28px;
        -webkit-user-select: none;
        user-select: none;
    }

    .sidebar {
        margin-top: 15px;
    }

    #menuToggle a {
        text-decoration: none;
        color: white;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #menuToggle a img {
        height: 14px;
    }

    #menuToggle a:hover {
        color: #f4ce24;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    #menuToggle span {
        display: block;
        width: 25px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: #000000;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition:
            cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-1px, -4px);
        background: white;
    }

    /* #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    } */

    #menuToggle input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 4px);
    }

    #menu {
        position: absolute;
        right: -13px;
        top: 0;
        width: 330px;
        min-height: 100vh;
        height: 100vh;
        padding: 35px;
        padding-top: 45px;
        background: #26477f;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        overflow-y: scroll;
    }

    #menu li {
        padding: 18px 0;
        font-size: 24px;
        color: white;
        width: 250px;
    }

    #menuToggle input:checked ~ ul {
        transform: none;
    }

    .searchMobile {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
    }

    .sideNav {
        width: 240px;
        z-index: inherit;
    }

    li .active {
        color: #b31029 !important;
    }
    .active li {
        color: #b31029 !important;
    }
    .search-container {
        display: none;
    }

    .searchToggle {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
        background-color: transparent;
        outline: 0;
        border: 0;
    }

    .searchiconmobile {
        fill: white;
    }

    .searchiconmobile:hover {
        fill: #f4ce24;
    }

    .textMobile {
        color: white;
        position: relative;
        margin-left: -30px;
        z-index: 50;
        margin-top: -50px;
        color: #f4ce24;
    }

    .textMobile:hover {
        color: #f4ce24;
    }

    .searchinputMobile {
        width: 100%;
        max-width: 700px;
        border-radius: 3px;
        border: none;
        margin: 35px auto auto;
        display: block !important;
    }

    .browseText {
        margin-left: 40px;
        margin-top: -25px;
        display: block;
        color: white;
    }
    .our_products-sec.sectors {
        position: relative;
    }
    .our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow {
        bottom: calc(78% + 1px);
    }

    .card-wrapper {
        width: 25%;
        max-width: 160px;
        min-width: 130px;
    }

    .cardimg {
        width: 100%;
        max-width: 177px;
        height: 100%;
        max-height: 252px;
    }

    .card-wrapper p {
        font-size: 15px;
    }

    .card {
        width: 100%;
        max-width: 900px !important;
    }

    .letter {
        width: 7.14% !important;
    }

    .counter-sec .counter-field .counter-number {
        margin-left: 0;
    }

    .products-listing .our_products-sec.productlist .sec-bottom .sec-title {
        text-align: left;
        max-width: 100%;
    }

    .products-listing .our_products-sec.productlist .sec-top-title {
        text-align: left;
    }

    .products-listing .bread-crumbs {
        padding: 20px 15px;
    }

    .products-listing .our_products-sec.products {
        padding: 15px 15px;
    }

    .products-listing .our_products-sec.products .carousel__item img {
        height: 200px;
    }
    .carousel-caption {
        bottom: 1rem;
    }
    .download-center .mobile-filter .fixedDiv .nosubmit {
        align-items: center !important;
        justify-content: center;
        padding-left: 42px !important;
        background-position: calc(40% + 1px) !important;
    }
    .our_products-sec.mobile-searchproduct .fixedDiv .filter {
        align-items: center !important;
        justify-content: center;
        padding-left: 26px !important;
        background-position: calc(25% + 1px) !important;
    }
    .Certification .our_products-sec.certification .certificates .certificate-box {
        height: 320px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) { 

    .nav-bar .parent li {
        margin: 0 10px !important;
    }
}