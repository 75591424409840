html {
  scroll-behavior: auto !important;
}

body {
  font-family: 'Archivo', sans-serif !important;
}

.page {
  background-color: #fff;
  width: 100%;
  float: none;
  position: absolute;
  box-shadow: none;
  overflow: hidden;
  min-height: 100%;
}


/* #menuToggle {
  display: none;
} */

.searchicon {
  position: absolute;
  width: 100%;
  z-index: 100;
  fill: grey;
}

.searchMobile {
  display: none;
}

.browseText {
  display: none;
}

.fold {
  width: 170px;
  height: 70px;
}

.foldtext {
  white-space: pre-line;
  width: 180px;
  height: 50px;
  margin-top: -15px;
}


.searchinputMobile {
  display: none;
}

/*---*/

.headerWrapper {
  height: 66px;
  display: block;
}

.header-bar {
  position: fixed;
  width: 100%;
  height: 66px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
}

.header-outer-wrapper {
  background-position: top;
  width: 100%;
  height: 100%;
  position: relative;
}

.header-inner-wrapper {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  display: none;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.header-bar .logo-wrapper {
  position: relative;
  justify-content: center;
} 
.logo-wrapper .jersey-logo img {
  display: block;
  height: auto;
  max-height: 53px;
}
.logo-wrapper .manufacturing-logo img {
  display: block;
  height: auto;
  max-height: 40px;
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  z-index: 1;
  margin-left: 750px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #78b6fa;
  font-family: inherit;
}

.logo {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
} 

.user-menu {
  min-width: 0;
  width: 200px;
  order: 1;
}

nav ul {
  list-style: none;
  list-style-image: none;
}

.loginmenu {
  margin: 0;
  padding: 0;
  display: flex;
}

.firstitem {
  border: none !important;
}

li a {
  border-left: none;
}

.redeem {
  width: 323px;
}

.springSale {
  width: 166px;
}

.vip {
  width: 130px;
}

.vipbtn {
  width: 130px;
}

.springSalebtn {
  width: 166px;
}

.skyStoreitem {
  width: 207px;
}

.redeembtn {
  width: 184px;
  margin-left: 130px;
}

.menu-item {
  border-left: 1px solid #fff;
  position: relative;
  display: block;
  width: 90px;
}

.menu-item>.anchor {
  color: #fff;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.search-container {
  display: inline-block;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  width: 70px;
  margin-left: 270px;
  order: 2;
}

.navbar-wrapper {
  width: 100%;
  background-color: #fff;
  height: 66px;
  border: 1px solid #f4f8fa;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
}

.nav-bar {
  max-width: 1140px;
  margin: 0 80px;
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-bar .parent {
  display: flex;
  padding: 0;
  margin: 0;
  height: 50px;
  position: absolute;
  right: 75px;
  align-items: center;
  justify-content: center;
}

.nav-bar .parent li {
  margin: 0 20px;
}
.nav-bar .parent li:last-child {
  margin-right: 6px;
}

.nav-bar .parent li a.active {
  border-bottom: 2px solid #00A6EF;
}

.nav-bar a {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 0;
}

.nav-bar .loginbtn {
  border: 2px solid #000000;
  opacity: 1;
  background: #fff !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 8px 18px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-self: center;
}

.nav-bar .loginbtn:hover {
  background: #00A6EF !important;
  border: 2px solid #18A6EF;
  opacity: 1;
  color: #fff !important;
}

.nav-bar .loginbtn:hover svg {
  fill: #fff;
}

.nav-bar .loginbtn svg {
  fill: #000000;
  height: 21px;
  width: 21px;
  margin-top: -5px;
  margin-right: 8px;
}

.expandright {
  right: 49px;
  /* Button width-1px */
}

.expandright:focus {
  right: 49px;
}


/*Carousel*/
.carouselItem {
  width: 100%;
  margin: auto;
  background-color: rgb(3, 15, 31);
}

.carousel {
  margin: auto;
  display: flex;
}

.carousel-inner {
  width: 100%;
  /* max-width: 1600px !important; */
  height: 100%;
  margin: auto;
}

/* .carousel-control-next {
  opacity: .1;
}

.carousel-control-prev {
  opacity: .1;
} */

.carousel-control-prev-icon {
  z-index: 999;
  width: 4rem;
  height: 3rem;
  margin-left: 200px;
  border: 7px solid rgba(0, 0, 0, -2.6);
  border-radius: 50%;
  padding: 27px;
  background-color: rgb(0, 0, 0, 0.4);
  opacity: 1;
  display: none;
}

.carousel-control-next-icon {
  z-index: 999;
  width: 4rem;
  height: 3rem;
  margin-right: 200px;
  border: 7px solid rgba(0, 0, 0, -2.6);
  border-radius: 50%;
  padding: 27px;
  background-color: rgb(0, 0, 0, 0.4);
  opacity: 1;
  display: none;
}

.carouselItem:hover span {
  cursor: pointer;
  transition: unset;
  display: block;
}

.carousel-caption {
  bottom: 8rem;
  color: white !important;
  font-size: 18px;
  width: 100%;
  max-width: 800px;
  min-width: 500px;
  z-index: 1;
  left: unset !important;
  right: unset !important;
  display: flex;
  flex-direction: column;
}

.carousel-caption h2 {
  /* width: 100%; */
  max-width: 394px;
  margin-left: 80px;
  font-size: 50px;
  line-height: 56px;
}
.carousel-caption p{
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 25px;
  font-weight: 300;
  text-align: left;
  margin-left: 80px;
  max-width: 464px;
  margin-top: 10px;
}
.carousel-caption .certificatin-img{
  position: absolute;
  bottom: 127px;
  left: 344px;
  height: 60px;
}
.carousel-caption .cap-title{
  display: flex;
  align-items: center;
}
.carousel-caption a{
  text-decoration: none;
  color: inherit;
}
.carousel-indicators {
  z-index: 50;
  bottom: 11rem;
  justify-content: flex-end;
  opacity: 0.5 !important;
}

.carousel-indicators button {
  min-height: 8px;
  max-width: 8px;
  min-width: 8px !important;
  border: 1px solid white !important;
  border-radius: 15px !important;
  margin-right: 8px !important;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
  height: 100%;
  width: 100%;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}

.carousel-item .w-100 {
  width: 100% !important;
}

.d-block {
  display: block !important;
}

.carousel-item img {
  vertical-align: middle;
  /* height: 536px; */
  object-fit: contain;
}
.carousel-item img.mobile-banner{
  display: none;
}
.carousel {
  position: relative;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  /* margin-right: 15%; */
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
  display: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 14rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;
}

.carousel-caption .caption-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  border-radius: 0;
  height: 45px;
  margin-left: 80px;
  width: 170px;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.carousel-caption .caption-btn img {
  height: 12px;
  margin-left: 8px;
}

.floating-chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 64px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 1;
  background: #2FCCA7;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* transition: height 0.5s;
  -webkit-transition: height 0.5s; */
  text-align: center;
  overflow: hidden;
  /* transition: 1.5s; */
  font-size: 14px;
  z-index: 9;
}

.floating-chat.enter {
  transform: translateY(0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}

.floating-chat svg {
  height: 18px;
  width: 18px;
}

.floating-chat span {
  display: none;
  margin-left: 8px;
}

.floating-chat:hover {
  height: 40px;
  width: 155px;
  border-radius: 29px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px;
}

.floating-chat:hover span {
  display: block;
}

/*- Home page css -*/
.our_products-sec {
  padding: 50px 80px;
}

.our_products-sec .sec-top-title {
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.our_products-sec .sec-title {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: left;
  font-size: 35px;
  text-transform: inherit;
  font-weight: 500;
  margin-bottom: 0;
}

.our_products-sec .sec-bottom-title {
  letter-spacing: 0px;
  color: #000000;
  text-transform: inherit;
  opacity: 1;
  text-align: justify;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  max-width: 436px;
  line-height: 24px;
}

.our_products-sec .sec-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our_products-sec .sec-bottom span a {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}
.our_products-sec .sec-bottom span a:hover {
  color: #0056b3;
}
.our_products-sec .sec-bottom span a img {
  height: 14px;
}

.our_products-sec .sec-bottom span a img.more-white {
  display: none;
}
.our_products-sec.our-products .bottom-more{
  display: none;
  margin-top: 30px;
}
.our_products-sec.our-products .bottom-more .button-more a{
  background-color: #000;
  color: #fff;
}

.our_products-sec .indicators {
  border: 1px solid #000000;
  opacity: 1;
  padding: 15px 10px;
  border-radius: 50%;
  background: #fff;
  color: #000000;
}

.our_products-sec .indicators.next {
  background: #000000;
  color: #fff;
}

.our_products-sec .indicators.prev {
  margin-right: 10px;
}

.our_products-sec .product-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow--right::before {
  background-image: url(../images/next-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.our_products-sec .product-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow--left::before {
  background-image: url(../images/prev-button-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item,
.our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item{
  margin: 0 5px;
}

.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item img {
  width: 100%;
  height: 210px;
  object-fit: contain;
  /* background: #f5f7fa; */
}
.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .image-box{
  background: #f5f7fa;
  padding: 30px;
  height: 100%;
  margin: 0;
  /* padding: 0;
  background: #fff; */
  overflow: hidden;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .image-box img{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .image-box:hover{
  background: transparent linear-gradient(1deg, #18a6ef1f 0%, #e0eef682 100%);
}
.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .image-box:hover img{
  -webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item {
  /* height: 587px; */
  height: 100%;
  /* width: 100%; */
  min-height: 100%;
  max-height: 100%;
}
.our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

.our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 96.8%;
  opacity: 0;
  transition: .5s ease;
  background:  transparent linear-gradient(186deg, #00000024 0%, #1456c7c9 100%);
  margin: 0 5px;
}

.our_products-sec .sector-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item:hover .overlay {
  opacity: 1;
}

.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item .carousel__caption p {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item a{
  text-decoration: none;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow {
  background: #000000;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  z-index: 1;
}

.our_products-sec .product-carouselItem .custom-dot {
  background: #F5F7FA;
  width: 100%;
  height: 2px;
  border-width: 0;
}
.our_products-sec .product-carouselItem .react-multi-carousel-dot-list  li {
  width: 100%;
}
.our_products-sec .product-carouselItem .custom-dot--active {
  background: #304972;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list .react-multi-carousel-track{
  padding-bottom: 40px;
}
.our_products-sec .product-carouselItem .react-multi-carousel-list button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  background: #000 !important;
}



.our_products-sec .sector-carouselItem {
  margin: 50px 0 0;
}

.our_products-sec .sector-carouselItem .carousel__caption {
  position: absolute;
  bottom: 6px;
  padding: 6px 20px;
  max-width: 146px;
}

.our_products-sec .sector-carouselItem .carousel__caption p {
  font-weight: 300;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  margin: 10px 0;
  color: #fff;
  line-height: 24px;
}
.our_products-sec.sectors {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
.our_products-sec.sectors .sec-bottom-title{
  max-width: 400px !important;
}
.our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow{
  right: 116px;
  z-index: 99;
  /* top: calc(32% + 1px); */
  bottom: calc(71% + 14px);
  margin-right: 31px;
}
.our_products-sec.sectors .sector-carouselItem .react-multi-carousel-list{
  position:inherit;
}
.our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow--left {
  left: unset !important;
}
.our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow--right {
  right: calc(2% + 25px) !important;
}

.our_products-sec.sectors .sector-carouselItem .react-multiple-carousel__arrow--right::before {
  background-image: url(../images/next-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.our_products-sec.sectors .sector-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow--left::before {
  background-image: url(../images/next-button-black.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
 
}
.our_products-sec.sectors .sector-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow--left{
  border: 1px solid #000000;
  opacity: 1;
  padding: 15px 10px;
  border-radius: 50%;
  background-color: #fff;
  color: #000000;
  outline: none;
  padding: 10px;
}
.our_products-sec.sectors .sector-carouselItem .react-multi-carousel-list .react-multiple-carousel__arrow--right{
  border: 1px solid #000000;
  opacity: 1;
  padding: 15px 10px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  outline: none;
  padding: 10px;
}

.our_products-sec .product-carouselItem {
  /* border-bottom: 2px solid #F5F7FA; */
  margin: 50px 0 0;
}

/* .our_products-sec .product-carouselItem:after {
  content: '';
  display: block;
  border-bottom: 2px solid #304972;
  margin-bottom: -2px;
  max-width: 400px;
  padding-top: 20px;
} */
.our_products-sec .product-carouselItem .progress{
  height: 2px;
  background-color: #F5F7FA;
  margin-top: 20px;
}
.our_products-sec .product-carouselItem .progress .progress-bar{
  background-color: #304972;
}

.our_products-sec .product-carouselItem .slider {
  margin: 0 auto;
  max-width: 1200px;
}

.our_products-sec .product-carouselItem .swiper-container {
  padding-bottom: 20px;
}

.our_products-sec .product-carouselItem .swiper-scrollbar {
  background: #d6d6d6;
}

.our_products-sec .product-carouselItem .swiper-container-horizontal > .swiper-scrollbar {
  border-radius: 2px;
  height: 5px;
  width: 200px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.our_products-sec .product-carouselItem .swiper-wrapper img {
  width: 100%;
}


.counter-sec {
  background-color: #031d47;
  padding: 50px 80px;
  color: #fff;
  height: 600px;
}

.counter-sec .sec-top-title {
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  opacity: 1;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 10px;
  opacity: 0.9;
}

.counter-sec h5 {
  letter-spacing: 0px;
  color: #ffffffed;
  text-transform: inherit;
  opacity: 1;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 32px;
  max-width: 700px;
}

.counter-sec h5 p {
  animation: typing 3.5s steps(30, end) infinite;
}

@keyframes typing {
  from {
    width: 100%
  }

  to {
    width: 100%
  }
}

.counter-sec .count-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ffffff1c;
}

.counter-sec .count-col:first-child {
  justify-content: flex-start;
}
.counter-sec .count-col:first-child .counter-label{
  max-width: 140px !important;
}
.counter-sec .count-col:last-child {
  border-right: 0;
}

.counter-sec .knomore-sec {
  display: none;
}

.counter-sec .knowmore-btn {
  background: transparent;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  border-radius: 0;
  height: 45px;
  width: 150px;
  margin-top: 0px;
  text-decoration: none;
}

.counter-sec .knowmore-btn img {
  height: 14px;
}
.counter-sec  .moving-arrows {
  position: relative;
  width: 100px;
  height: 60px; 
}
.counter-sec .arrow {
  position: absolute;
  width: 20px; 
  height: 20px; 
  background-color: #000; 
}
.counter-sec  .arrow1 {
  top: 0;
  left: 0;
  animation: moveArrow 1.5s infinite;
}
.counter-sec  .arrow2 {
  top: 20px; 
  left: 20px; 
  animation: moveArrow 1.5s infinite 0.5s; 
}
.counter-sec .arrow3 {
  top: 40px; 
  left: 40px; 
  animation: moveArrow 1.5s infinite 1s; 
}
@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px); 
  }
  100% {
    transform: translateX(0);
  }
}

.counter-sec svg {
  margin: 0 auto;
  float: right;
}
.counter-sec svg .draw-arrow {  
  
  stroke-width: .7;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  /* -webkit-animation: draw 2s infinite alternate; */
  animation: draw 4s linear infinite forwards;

}


.HomeSubsidiaries_bg_line__OTckB {
  z-index: 0;
  position: absolute;
  pointer-events: none;
  max-width: 23.55%;
  right: -7%;
  bottom: 60%;
  width: 59.27%;
  --bs-aspect-ratio: 100%;
}

.ratio:before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.SvgLineBg_bg_line__Aq2b0 {
  display: block;
  z-index: 1;
  pointer-events: none;
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SvgLineBg_bg_line__Aq2b0.SvgLineBg_bg_line_flip__l2Jky svg {
    width: 95%;
    height: 95%;
    transition:all 1s ease-out;
    transform: matrix(-1, 0, 0, -1, 0, 0);
  }
  /* .partnership:hover svg {
    transform: rotate(270deg);
  } */
  .partnership svg {
    animation: partnerdraw 2s linear infinite alternate;
  }
  
  @keyframes partnerdraw{
    0% {
      transform: rotate(0deg);
    }
    /* 50% {
      transform: rotate(90deg);
    } */
    100% {
      transform: rotate(112deg);
    }
}


  @keyframes rotate{
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(360deg);
    }
}


.HomeSubsidiaries_bg_line__OTckB.footer{
  max-width: 38.55% !important;
  left: 15% !important;
  bottom: 330px !important;
  width: 71.27% !important;
}
.HomeSubsidiaries_bg_line__OTckB.footer .SvgLineBg_bg_line__Aq2b0.SvgLineBg_bg_line_flip__l2Jky svg {
  transition:all 1s ease-out;
  /* transform: rotate(334deg); */
  animation: footerdraw 2s linear infinite alternate;
}
/* .footer-sec:hover .HomeSubsidiaries_bg_line__OTckB.footer svg {
  transform: rotate(270deg);
} */
@keyframes footerdraw {
  0% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
.page .content-sec{
  background: #fff;
  position: relative;
  z-index: 2;
}
@keyframes moveArrow3 {
  0% {
    transform: rotate(105deg);
  }
  100% {
    transform: rotate(-105deg);
  }
}
.HomeSubsidiaries_bg_line__OTckB.about{
  bottom: -16px !important;
  right: 0 !important;
}
.HomeSubsidiaries_bg_line__OTckB.about .SvgLineBg_bg_line__Aq2b0.SvgLineBg_bg_line_flip__l2Jky svg {
  stroke-width: .7;
    stroke: #fff;
    fill: none;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: aboutdraw 4s linear infinite forwards;
}
.HomeSubsidiaries_bg_line__OTckB.about .SvgLineBg_bg_line__Aq2b0.SvgLineBg_bg_line_flip__l2Jky svg {
  width: 95%;
  height: 95%;
  transition: none !important;
  transform: unset !important;
}

@keyframes aboutdraw {
  to {
     stroke-dashoffset: 0;
   }
 }

 .HomeSubsidiaries_bg_line__OTckB.career{
  max-width: 33.55%;
  left: -15%;
  bottom: 13%;
}
.benefits-sec .HomeSubsidiaries_bg_line__OTckB.career svg {
  animation: careerdraw 2s linear infinite alternate;
}
@keyframes careerdraw{
  0% {
    transform: rotate(4deg);
  }
  /* 50% {
    transform: rotate(90deg);
  } */
  100% {
    transform: rotate(92deg);
  }
}

/* .benefits-sec:hover .HomeSubsidiaries_bg_line__OTckB.career svg {
  transform: rotate(270deg);
} */
.partnership{
  position: relative;
  }
.partnership .image-b svg {
  width: 65px;
  height: 125px; 
  margin: 0 auto;
  transform: unset !important;
}
.partnership .image-b svg .draw-arrow {  
  stroke-width: 0.7;
  stroke: #fff; 
  fill: none;
  stroke-dasharray: 100;
  stroke-dashoffset: 100; 
  /* animation-duration: 2.0s;
  animation-fill-mode: forwards;
  animation-name: draw; */
  animation: draw 2s infinite alternate;
}


@keyframes draw {
 to {
    stroke-dashoffset: 0;
  }
}

.partnership {
  padding: 0;
  height: 450px;
}
.partnership .image-box {
  height: 450px;
}
.partnership img {
  height: 100%;
  object-fit: cover;
  min-height: 0;
  max-height: 100%;
  width: 100%;
}



.partnership .partnership-right {
  padding: 80px 30px 80px 30px;
  height: 100%;
}

.partnership .partnership-right h5 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  max-width: 340px;
  margin-bottom: 15px;
}

.partnership .row {
  margin-right: 0;
}

.partnership .partnership-right .partner-bottom-p {
  letter-spacing: 0.22px;
  font-size: 15px;
  color: #fff;
  max-width: 525px;
}

.partnership .partnership-right img {
  height: 52px;
  width: auto;
  margin-top: 15px;
}
.partnership .image-b{
  position: absolute;
  right: 7px;
}
.partnership .image-b img{
  height: 210px;
}
.partnership .image-b img.before-hovers{
  display: block;
  /* animation: moveArrow1 1.5s infinite 1s; */
}
@keyframes moveArrow1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(6px); 
  }
  100% {
    transform: translateY(0);
  }
}
.partnership .image-b img.after-hover{
  display: none;
}
/* .partnership:hover .image-b img.after-hover{
  display: block;
}
.partnership:hover .image-b img.before-hover{
  display: none;
} */
.excellence .carousel__item.left img:not(.overlay-img) {
  max-height: 100%;
  min-height: 100%;
}

.excellence .sector-carouselItem .carousel__caption {
  max-width: 100% !important;
}
.our_products-sec.excellence .bottom-more{
  margin-top: 30px;
  display: none;
}
.our_products-sec.excellence .carousel__item .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.our_products-sec.excellence .carousel__item:hover .text img {
  opacity: 0.8;
}

.our_products-sec.excellence .carousel__item:hover .middle {
  opacity: 1;
}

.our_products-sec.excellence .carousel__item .text {
  background-color: #000;
  color: white;
  font-size: 16px;
  padding: 20px;
  border-radius: 50%;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.our_products-sec.excellence .carousel__item .text img {
  height: 15px;
  min-height: auto;
}

.our_products-sec.excellence .carousel__item .text p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  opacity: 0.8;
  margin-top: 6px;
}

.our_products-sec.excellence .carousel__item{
  -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  height: 100%;
}
.our_products-sec.excellence .carousel__item figure {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}
.our_products-sec.excellence .carousel__item figure img:not(.overlay-img) {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.our_products-sec.excellence .carousel__item figure:hover img:not(.overlay-img) {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.seemore-btn {
  background: transparent;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 0;
  height: 39px;
  width: 150px;
  margin-top: 0px;
}

.seemore-btn a {
  color: inherit;
  text-decoration: none;
}
.our-reference .seemore-btn img{
  margin-left: 8px;
}
.our_products-sec.certification {
  background: #F5F7FA;
  padding: 50px 190px
}
.our_products-sec.certification.home{
  padding: 50px 80px;
}
.our_products-sec.certification.home .certificates .certificate-box{
  height: 250px;
}
.our_products-sec.certification.home .react-multi-carousel-item--active:nth-last-child(1) .certificate-box{
  margin-right: 0 !important;
}
.our_products-sec.certification.home .sec-bottom{
  align-items: baseline !important;
}
.our_products-sec.certification .seemore-btn {
  margin-top: 15px;
}

.our_products-sec.certification .seemore-btn img {
  height: 14px;
  margin-left: 8px;
}

.our_products-sec.certification .sec-bottom {
  flex-direction: column;
  align-items: baseline !important;
}

.our_products-sec.certification .certificates .certificate-box {
  padding: 18px;
  background: #FFFFFF;
  opacity: 1;
  width: auto;
  height: 315px;
  margin: 0 0 0 12px;
  margin-bottom: 15px;
}

.our_products-sec.certification .certificates .certificate-box h5 {
  letter-spacing: 0.23px;
  color: #223555;
  text-transform: inherit;
  opacity: 1;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}

.our_products-sec.certification .certificates .certificate-box p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.our_products-sec.certification .certificates .certificate-box img {
  height: 60px;
  margin-bottom: 20px;
}

.our_products-sec.latestNews .latest-news-sec {
  margin-top: 50px;
}
.our_products-sec.latestNews .latest-news-sec a{
  text-decoration: none;
}
.our_products-sec.latestNews .latest-news-sec .bottom-more{
  display: none;
  margin-top: 30px;
}

.our_products-sec.latestNews .latest-news-sec .news-section .news-time {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.our_products-sec.latestNews .latest-news-sec .news-section h5 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 26px;
}

.our_products-sec.latestNews .latest-news-sec .news-section p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}
.our_products-sec.latestNews .latest-news-sec .news-section .image-box {
  height: 170px;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.our_products-sec.latestNews .sec-bottom span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.our_products-sec.latestNews .latest-news-sec .news-section img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  /* margin-bottom: 15px; */
}

.our_products-sec.contactus {
  background: transparent linear-gradient(241deg, #aadeff73 0%, #ced4f54d 100%);
  padding: 100px 80px;
}

.our_products-sec.contactus .sec-title {
  max-width: 246px;
  line-height: 55px;
  font-size: 46px;
}

.our_products-sec.contactus .contact-info {
  display: flex;
  align-items: center;
  margin-top: 42px;
}

.our_products-sec.contactus .contact-info p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 0px;
  border-bottom: 1px solid #00000052;
  margin-bottom: 0;
}
.our_products-sec.contactus .contact-info p a{
  text-decoration: none;
  color: inherit;
}
.our_products-sec.contactus .contact-info img {
  height: 40px;
  margin-right: 10px;
}

.our_products-sec.contactus .contact-form label {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
}
.our_products-sec.contactus .contact-form  .PhoneInput .PhoneInputInput{
  font-size: 14px !important;
  font-weight: inherit !important;
  color: #323840 !important;
  border-bottom: 1px solid #000 !important;
}
.our_products-sec.contactus .contact-form  .PhoneInput .PhoneInputInput::placeholder{
  color: #323840 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.our_products-sec.contactus .contact-form label.form-check-label{
  margin-left: 10px;
}
.our_products-sec.contactus .contact-form label.form-check-label a{
  color: inherit;
  text-decoration: underline;
}
.our_products-sec.contactus .contact-form input[type="checkbox"] {
  height: 16px;
  width: 16px;
  accent-color: #26477F;
  position: relative;
  top: 3px;
}
.our_products-sec.contactus .contact-form .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  height: 20px;
  padding: 0;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-transform: inherit;
}

.did-floating-label-content {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}

.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 11px;
  padding: 0;
  background: transparent;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0;
  background: transparent;
  color: #323840;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;

}

.did-floating-input:focus {
  outline: none;

}

.did-floating-input:focus~.did-floating-label {
  top: -15px;
  font-size: 13px;
  color: #81868C !important;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -15px;
  font-size: 13px;
  color: #81868C !important;
}
.PhoneInput .PhoneInputInput{
  background: transparent;
  border: none;
  border-bottom: 1px solid #81868c38;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  padding: 0;
  text-align: left;
  text-transform: inherit;
  margin-top: 12px;
}
.PhoneInput .PhoneInputInput:focus{
  border-bottom:1.2px solid #000000;
}
.PhoneInput .PhoneInputInput::placeholder{
  color: #898989;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.PhoneInput .PhoneInputCountryIcon--border{
  box-shadow: none;
  margin-top: 14px;
}
.PhoneInput  .PhoneInputCountryIcon svg{
  margin-top: 8px;
}
.our_products-sec.contactus .contact-form .submit-btn {
  background: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0;
  margin-top: 20px;
}
.our_products-sec.contactus .contact-form .submit-btn.btn:disabled {
  opacity: .65 !important;
}
.our_products-sec.contactus .contact-form .submit-btn img {
  height: 14px;
  margin-left: 10px;
}

.counter-sec .counter-field {
  margin-top: 90px;
}

.counter-sec .counter-field .counter-number {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: -16px;
}

.counter-sec .counter-field .counter-number img {
  margin-right: 8px;
}

.counter-sec .counter-field .counter-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 1;
  max-width: 145px;
  margin-left: 36px;
}


/*- Home page css ends -*/

/*- About page css ends -*/
.about-us .our_products-sec .sec-title {
  max-width: 300px;
  line-height: 40px;
}
.about-us .our_products-sec.company-info .sec-title{
  margin-top: 28px;
}
.about-us .our_products-sec.latestNews .sec-title {
  max-width: max-content !important;
}
.about-us .our_products-sec.latestNews .sec-bottom span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us .about-right {
  margin: 64px 80px 0 0px;
}

.about-us .about-right p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  margin: 25px 12px 15px;
}
.about-us .about-right .read-more {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  margin: 25px 12px;
  text-decoration: underline;
}

.about-us .about-image {
  padding: 50px 80px;
  height: 100%;
  min-height: 0;
  max-height: 100%;
  width: 100%;
}

.about-us .about-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about-us .about-image .mobile-img{
  display: none;
}

.about-us .about-accredit {
  padding: 0px 80px 50px;
  display: flex;
  align-items: center;
}

.about-us .about-accredit img {
  height: 90px;
}

.about-us .about-accredit p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  text-transform: inherit;
  opacity: 1;
  margin-left: 20px;
  max-width: 500px;
  margin-bottom: 0;
}

.about-us .our_products-sec.facilities {
  background: #F8FBFD;
  padding: 50px 80px 0;
}

.about-us .facility-box {
  display: flex;
  align-items: flex-start;
  margin: 0px 0 40px;
}

.about-us .facility-box .facility-des {
  margin: 0 20px;
}

.about-us .facility-box .facility-des h2 {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}

.about-us .facility-box .facility-des p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 0.65;
  font-size: 15px;
  font-weight: 400;
}

.about-us .about-facilty {
  margin: 20px 0 0;
  /* background-image: url(../images/facility-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 34vh;
  background-position-x: 100%;
  background-position-y: 100%; */
}

.about-us .about-facilty .facility-box img {
  height: 14px;
}

.about-us .our_products-sec.mission-vision {
  background: transparent linear-gradient(246deg, #AADEFF 0%, #CED4F5 100%);
}

.about-us .our_products-sec.mission-vision .mission-box {
  background: #FFFFFF;
  border-radius: 2px;
  opacity: 1;
  padding: 32px;
  min-height: 90%;
  max-height: 90%;
  /* height: 250px; */
  margin-top: 30px;
}

.about-us .our_products-sec.mission-vision .mission-box .mission-title {
  display: flex;
  align-items: center;
}

.about-us .our_products-sec.mission-vision .mission-box .mission-title img {
  height: 40px;
}

.about-us .our_products-sec.mission-vision .mission-box .mission-title h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 10px;
  text-align: left;
}

.about-us .our_products-sec.mission-vision .mission-box p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: 15px;
  line-height: 28px;
}

.about-us .our_products-sec.mission-vision .sec-title {
  max-width: 100%;
}

.about-us .our_products-sec.ourvalues {
  background: #041D48;
}

.about-us .our_products-sec.ourvalues .sec-top-title {
  color: #fff;
}

.about-us .our_products-sec.ourvalues .sec-title {
  max-width: 100%;
  color: #fff;
}

.about-us .our_products-sec.ourvalues .value-box {
  border: 1px solid #FFFFFF29;
  border-radius: 2px;
  opacity: 1;
  padding: 22px;
  margin: 8px 0;
}

.about-us .our_products-sec.ourvalues .values {
  margin: 45px 0;
}

.about-us .our_products-sec.ourvalues .value-box .value-title {
  display: flex;
  align-items: center;
}

.about-us .our_products-sec.ourvalues .value-box .value-title h2 {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  opacity: 1;
  color: #fff;
  margin-left: 20px;
  margin-bottom: 0;
}

.about-us .our_products-sec.ourvalues .value-box .value-title img {
  height: 32px;
}

.about-us .our_products-sec.company-info {
  background: transparent linear-gradient(132deg, #e9ddee42 0%, #e0eef682 100%) 0% 0% no-repeat;
  padding: 50px 180px;
}

.about-us .our_products-sec.company-info .sec-bottom img {
  height: 200px;
}

.about-us .our_products-sec.company-info .about-services {
  margin: 50px 0 0;
}

.about-us .our_products-sec.company-info .about-services .service-box {
  background: #fff;
  box-shadow: 0px 2px 4px #E9F0F779;
  opacity: 1;
  margin: 15px 0;
}

.about-us .our_products-sec.company-info .about-services .service-box .carousel__item .carousel__caption {
  position: absolute;
  top: 15px;
  width: 100%;
  color: #fff;
  padding: 22px;
}

.about-us .our_products-sec.company-info .about-services .service-box .service-logo {
  padding: 15px;
  width: 155px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.about-us .our_products-sec.company-info .about-services .service-box .service-logo img {
  height: 40px;
  object-fit: contain;
  width: 100%;
  margin-right: 10px;
}

.about-us .our_products-sec.company-info .about-services .bottom-more {
  margin: 40px 0 0;
}

.about-us .our_products-sec.company-info .about-services .bottom-more .button-more a {
  width: max-content;
}


.about-us .our_products-sec.company-info .about-services .service-box .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: calc(49.2% - 32.8px);
  left: calc(50% + 0px);
  width: calc(100% - 29px);
  height: calc(100% - 99px);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: left;
    
}

.about-us .our_products-sec.company-info .about-services .carousel__item:hover .text img {
  opacity: 0.8;
}

.about-us .our_products-sec.company-info .about-services .service-box:hover .middle {
  opacity: 1;
}

.about-us .our_products-sec.company-info .about-services .service-box .text {
  background-color: #000;
  padding: 11.2px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.about-us .our_products-sec.company-info .about-services .service-box .text h2 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: left;
}

.about-us .our_products-sec.company-info .about-services .service-box .text p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  /* width: 350px; */
  line-height: 28px;
}
.about-us .our_products-sec.company-info .about-services .service-box .carousel__item{
  height: 220px;
}
.about-us .our_products-sec.company-info .about-services .service-box .carousel__item img{
  height: 100%;
}
.about-us .our_products-sec.company-info .about-services .service-box .middle.trading .text {
  background-color: #E78434;
}

.about-us .our_products-sec.company-info .about-services .service-box .middle.contracting .text {
  background-color: #A52129;
}

.about-us .our_products-sec.company-info .about-services .service-box .middle.manufacturing .text {
  background-color: #235691;
}

.about-us .our_products-sec.company-info .about-services .service-box .middle.services .text {
  background-color: #4C9FE7;
}

.about-us .our_products-sec.latestNews .latest-news-sec .bottom-more {
  display: none;
}

.about-us .our_products-sec.history .sec-title {
  text-align: center;
  max-width: 100%;
}

.timeline {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}

.timeline ol {
  font-size: 0;
  /* width: 100vw; */
  width: 100%;
  padding: 360px 0;
  transition: all 1s;
  margin: 50px 0;
}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 150px;
  height: 3px;
  background: #041D48;
}


.timeline ol li:last-child {
  width: 25%;
}


.timeline ol li::after {
  content: "";
  position: absolute;
  top: 38px;
  z-index: 9;
  left: calc(101% + 1.5px);
  bottom: 0;
  width: 10px;
  height: 10px;
  transform: translateY(30%);
  border-radius: 50%;
  background: #00A6EF;
}

.timeline ol li:nth-child(odd)::after {
  top: -53px;
}

.timeline ol li div {
  position: absolute;
  left: calc(94% + 0px);
  width: 280px;
  padding: 15px;
  white-space: normal;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 50px;
}

.timeline ol li div img {
  height: 46px;
  object-fit: contain;
  margin-bottom: 10px;
}

.timeline ol li div p {
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  width: 200px;
}

.timeline ol li::before {
  content: "";
  background: #041D48;
  position: absolute;
  top: 100%;
  width: 2px;
  height: 40px;
  left: 7px;
}

.timeline ol li:nth-child(odd) div {
  top: -50px;
  transform: translateY(-100%);
}

.timeline ol li:first-child:before,
.timeline ol li:last-child:after {
  display: none;
}

.timeline ol li:nth-child(even)::before {
  top: -40px;
}

.timeline time {
  display: block;
  font-size: 22px;
  font-weight: 600;
  color: #041D48;
  margin-bottom: 8px;
}



.timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline .arrows button {
  position: absolute;
  top: 49.5%;
  background: #00A6EF;
  border: none;
  border-radius: 50%;
  padding: 2px;
  outline: none;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline .arrows .arrow__prev {
  left: calc(4.5% + 1px);
}

.timeline .arrows .arrow__next {
  right: calc(4.5% + 1px);
}

.timeline .disabled {
  opacity: 0.5;
}

.timeline .arrows img {
  height: 16px;
  margin-left: -11px;
}

.timeline-mobile {
  display: none;
}

/*- About page css ends -*/

/*- Product listing page css -*/

.products-listing .our_products-sec.productlist {
  background-image: url(../images/product-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.products-listing .our_products-sec.productlist .sec-bottom .sec-title {
  max-width: 560px;
  line-height: 42px;
}

.products-listing .our_products-sec.productlist .sec-top-title {
  color: #26477F;
}

.breadcrumb-not-active {
  letter-spacing: 0px;
  color: #617088;
  text-transform: capitalize;
  opacity: 0.8;
  font-size: 15px;
}

.breadcrumb-active {
  letter-spacing: 0px;
  color: #26477F;
  text-transform: capitalize;
  opacity: 1;
  font-size: 15px;
  text-decoration: none;
}

.breadcrumb-active:hover {
  text-decoration: none !important;
  cursor: inherit;
}

.breadcrumb-arrow {
  margin-left: 10px;
  margin-right: 10px;
}

.bread-crumbs {
  padding: 20px 80px;

}

.products-listing .our_products-sec.products {
  padding: 15px 80px;
}

.products-listing .our_products-sec.products .carousel__caption {
  margin: 15px 0;
}

.products-listing .our_products-sec.products .carousel__caption p {
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
}

.products-listing .our_products-sec.products .carousel__item.soldout .carousel__caption p {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.42;
}

.products-listing .our_products-sec.products .carousel__item img {
  height: 210px;
  /* background: #f5f7fa; */
  object-fit: contain;
  width: 100%;
}

.products-listing .our_products-sec.products .carousel__item .image-box{
  background: #f5f7fa;
  padding: 30px;
  height: 100%;
  margin: 0;
  /* padding: 0;
  background: #fff; */
  overflow: hidden;
}
.products-listing .our_products-sec.products .carousel__item .image-box img{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.products-listing .our_products-sec.products .carousel__item .image-box:hover{
  background: transparent linear-gradient(1deg, #18a6ef1f 0%, #e0eef682 100%);
}
.products-listing .our_products-sec.products .carousel__item .image-box:hover img{
  -webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.products-listing .our_products-sec.products a {
  text-decoration: none;
}

.products-list {
  background: #F5F7FA;
}

.products-list .our_products-sec.products {
  padding: 20px 80px 50px;
}

.products-list .our_products-sec.products .product-filter {
  margin-bottom: 20px;
}

.products-list .our_products-sec.products .product-filter form.nosubmit {
  border: none;
  padding: 0;
}

.products-list .our_products-sec.products .pdt-name-top {
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: left;
  margin-top: 15px;
}

.products-list .our_products-sec.products .product-filter input.nosubmit {
  width: 100%;
  padding: 8px 4px 8px 36px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  background-color: #F8FAFB;
  border: 1px solid #DCDEE4;
  border-radius: 6px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #858C98;
}
.products-list .our_products-sec.products .product-filter input.nosubmit::placeholder{
  font-size: 14px;
}

.products-list .our_products-sec.products .product-filter .filter-sec,
.Mobile-search-filter .Filter-filed .product-filter {
  background: #FFFFFF;
  padding: 15px 10px;
}


.products-list .our_products-sec.products .product-filter h4,
.Mobile-search-filter .Filter-filed .product-filter h4 {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
}
.Mobile-search-filter .Filter-filed .product-filter h4 {
  padding-bottom: 15px;
  border-bottom: 1px solid #E5E5E5;
}
.products-list .our_products-sec.products .product-filter .clear-btn,
.Mobile-search-filter .Filter-filed .product-filter .clear-btn{
  letter-spacing: 0px;
  color: #095291;
  text-transform: capitalize;
  opacity: 1;
  float: right;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 12px;
  margin-top: 0px;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
}
.products-list .our_products-sec.products .product-filter .accordion-body,
.Mobile-search-filter .Filter-filed .product-filter .accordion-body{
  position: relative;
}
.products-list .our_products-sec.products .product-filter .accordion-body .clear-btn,
.Mobile-search-filter .Filter-filed .product-filter .accordion-body .clear-btn {
  position: absolute;
  z-index: 999;
  top: -34px;
  right: 40px;
  margin: 0;
  margin-bottom: 0;
}

.products-list .our_products-sec.products .product-filter .clear-btn:hover,
.Mobile-search-filter .Filter-filed .product-filter .clear-btn:hover{
  text-decoration: underline;
}
.products-list .our_products-sec.products .product-filter .accordion,
.Mobile-search-filter .Filter-filed .product-filter .accordion {
  margin: 20px 0;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item {
  border-bottom: 1px solid #DCDEE4;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-button:not(.collapsed)::after,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-button:not(.collapsed)::after {
  content: "-";
  font-size: 30px;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-button::after,
.Mobile-search-filter .Filter-filed .accordion .accordion-button::after {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  font-size: 20px;
  line-height: 14px;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item .accordion-header button,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item .accordion-header button {
  letter-spacing: 0px;
  color: #000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 600;
  font-size: 15px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item .accordion-header,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item .accordion-header {
  margin-bottom: 0;
  margin: 12px 0;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body ul,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body ul {
  padding: 6px 15px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body .type-text,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body .type-text {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body .type-text h5,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body .type-text h5 {
  letter-spacing: 0px;
  color: #617088;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body .type-text a,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body .type-text a {
  letter-spacing: 0px;
  color: #095291;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body ul li,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body ul li {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  align-items: baseline;
  margin: 0px 0;
  list-style: none;
  /* white-space: nowrap; */
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-body ul li label,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body ul li label {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body ul li label{
  margin-bottom: 8px;
}
.products-list .our_products-sec.products .product-filter .accordion .accordion-body ul li input[type="checkbox"],
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-body ul li input[type="checkbox"] {
  accent-color: #095291;
  height: 15px;
  width: 15px;
  position: relative;
  top: 4px;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item:last-child .show,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item:last-child .show {
  border-bottom: none;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item .show,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item .show {
  border-bottom: 1px solid #085291;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item:last-child,
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item:last-child {
  border-bottom: none;
}

.products-list .our_products-sec.products .product-filter .accordion .accordion-item .accordion-header button[aria-expanded="true"],
.Mobile-search-filter .Filter-filed .product-filter .accordion .accordion-item .accordion-header button[aria-expanded="true"] {
  color: #095291 !important;
}

.products-list .our_products-sec.products .pdt-list-show a {
  text-decoration: none !important;
}
.products-list .our_products-sec.products .pdt-list-show .category-name{
  font-size: 14px;
  text-align: left;
  color: #000000;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 3px;
  margin-bottom: 10px;
  display: none;
}
.products-list .our_products-sec.products .pdt-list-show h4 {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-left: 3px;
}

.products-list .our_products-sec.products .pdt-list-show .carousel__item {
  background: #FFFFFF;
  padding: 10px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  max-height: 100%;
  min-height: 0;
  height: 280px;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .image-box{
  height: 105px;
  width: 140px;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .carousel__caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0 5px;
}
.products-list .our_products-sec.products .pdt-list-show .picture-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 132px;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item img {
  min-height: 0;
  max-height: 100%;
  height: 100%;
  width: 140px;
  object-fit: contain;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .no-image{
  border: 1px solid #DCDEE4;
  padding: 15px;
  height: 140px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
  flex-direction: column;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .no-image img {
  height: 65px;
  object-fit: contain;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .no-image p{
  font-size: 15px;
  color: #b4b2b2;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0;
}
.products-list .our_products-sec.products .pdt-list-show .carousel__item .carousel__caption h5 {
  letter-spacing: 0px;
  color: #095291;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  /* margin-bottom: 15px; */
}

.products-list .our_products-sec.products .pdt-list-show .carousel__item .carousel__caption p {
  letter-spacing: 0px;
  color: #152D55;
  text-transform: capitalize;
  opacity: 1;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 2px;
}
.no-pdt-found {
  margin-top: 42px;
}
.no-pdt-found h6{
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  color: #000;
  opacity: 1;
  margin-bottom: 15px;
}
.no-pdt-found p{
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000;
  opacity: 1;
}
.our_products-sec.products .related-serch{
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  color: #000;
  opacity: 1;
  margin-bottom: 15px;
  padding: 3px;
}
.no-pdt-found hr{
  margin: 35px 0;
}
.mobile-filter {
  display: none;
}

.fixedDiv {
  padding: 10px;
  background-color: #00A6EF;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  box-shadow: 0px 0px 2px 0px #00000099;
  display: flex;
  height: 45px;
}

.fixedDiv .nosubmit {
  width: 100%;
  padding: 0px 4px 0px 36px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 68px center;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: left;
  background-position: left;
  padding-left: 20px;
}

.fixedDiv .filter {
  background: none;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent url("../images/filter.png") no-repeat 55px center;
  background-color: transparent;
  background-position: center;
  padding-left: 72px;
  white-space: nowrap;
}

.fixedDiv hr {
  background: #4EC8FD;
  width: 3px;
  height: 100%;
  margin:0 15px;
}

.fixedFilter {
  padding: 10px;
  background-color: #0B2A60;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  box-shadow: 0px 0px 2px 0px #00000099;
  display: flex;
  height: 45px;
}

.fixedFilter .nosubmit {
  width: 100%;
  padding: 0px 4px 0px 36px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  text-decoration: underline;
}

.fixedFilter .filter {
  background: none;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  background-position: center;
}

.fixedFilter hr {
  background: #2E5598;
  width: 3px;
  height: 100%;
  margin-top: 0;
}

.Mobile-search-filter {
  display: none;
}

.Mobile-search-filter .Search-field,
.Mobile-search-filter .Filter-filed {
  padding: 15px;
  margin-top: 20px;
}

.Mobile-search-filter .Search-field .back-btn,
.Mobile-search-filter .Filter-filed .back-btn {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
}

.Mobile-search-filter .Search-field .back-btn svg,
.Mobile-search-filter .Filter-filed .back-btn svg {
  width: 15px;
  margin-right: 4px;
}

.Mobile-search-filter .Search-field form.nosubmit {
  border: none;
  padding: 0;
  margin: 20px 0;
}

.Mobile-search-filter .Search-field .nosubmit {
  width: 100%;
  padding: 15px 18px 15px 8px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #000;
  background-position: right;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #E5E5E5;
} 
.Mobile-search-filter .Search-field .nosubmit[value]:not([value=""]){
  border-bottom: 1px solid #0B2A60;
} 
.Mobile-search-filter .Search-field .nosubmit::placeholder{
  color:#00000073 !important;
}

.Mobile-search-filter .Search-field .nosubmit::placeholder {
  color: #000;
}

/*- Product listing page css ends -*/

/*- Product detail page css -*/



.product-detail-sec .product-detail-left .carousel__item,
.product-detail-carousel .image-gallery .image-gallery-slide-wrapper{
  background: #FAFBFC;
}

.product-detail-sec .our_products-sec.product-detail {
  padding: 20px 80px;
}

.product-detail-sec .product-detail-left .carousel__item img,
.product-detail-sec  .image-gallery-slide img:not(img[alt="hover-image"]){
  height: 300px !important;
  object-fit: contain;
}
.product-detail-sec  .image-gallery-slide img[alt="hover-image"]{
  background-color: #FAFBFC;
}

.product-detail-sec .product-detail-left .react-multiple-carousel__arrow,
.product-detail-sec .image-gallery .image-gallery-slide-wrapper .image-gallery-icon {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #5f779561;
  filter: none !important;
  opacity: 1;
  outline: none !important;
  border: none !important;
  border-radius: 35px;
  cursor: pointer;
  border-radius: 20px;
  min-height: 33px;
  min-width: 38px;
  opacity: 1;
  outline: 0;
  position: absolute;
  transition: all .5s;
  z-index: 1000;
  padding: 6px;
}
.product-detail-sec .image-gallery .image-gallery-slide-wrapper .image-gallery-icon.image-gallery-right-nav {
  right: 10px;
}
.product-detail-sec .image-gallery .image-gallery-slide-wrapper .image-gallery-icon.image-gallery-left-nav {
  left: 10px;
}
.product-detail-sec .image-gallery .image-gallery-icon[disabled] svg{
  stroke: #25477136 !important;
}
.product-detail-sec .image-gallery .image-gallery-slide-wrapper .image-gallery-icon .image-gallery-svg{
  stroke: #254771;
  height: 30px;
  width: 30px;
}
.product-detail-sec .product-detail-left .react-multiple-carousel__arrow::before {
  color: #254771;
}

.product-detail-sec .product-next-carousel {
  padding: 20px 0;
}

.product-detail-sec .product-next-carousel .carousel__item,
.product-detail-sec .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnail{
  margin-right: 15px;
  border: 1px solid #ECECED;
  background: #FAFBFC;
  padding: 15px;
  height: 81px;
}
.product-detail-sec .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container
{
  text-align: left !important;
}
.product-detail-sec .image-gallery-thumbnails-wrapper .image-gallery-thumbnails{
  padding: 20px 0 !important;
}
.product-detail-sec .image-gallery-thumbnails-wrapper .image-gallery-thumbnail.active{
  border: 1px solid #0B2A60 !important;
}
.product-detail-sec .product-next-carousel .carousel__item img,
.product-detail-sec .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnail img {
  height: 50px;
  object-fit: contain;
}
.product-detail-sec .product-next-carousel .react-multi-carousel-item--active:nth-last-child(2) .carousel__item{
  border-color: #0B2A60;
}

.product-detail-sec .product-detail .display-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  margin-right: 12%;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.product-detail-sec .product-detail .display-btn:hover {
  color: #fff;
}

.product-detail-sec .product-detail-right {
  margin: 0 20px 0 58px;
}

.product-detail-sec .product-detail-right .pdt-detail-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.product-detail-sec .product-detail-right .pdt-detail-title .right-sec{
  display: flex;
  position: absolute;
  right: 32px;
  margin-bottom: 25px;
}
.product-detail-sec .product-detail-right .pdt-detail-title .right-sec .share-loc{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.product-detail-sec .product-detail-right .pdt-detail-title h2 {
  max-width: 280px;
  width: 280px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 25px;
  font-weight: 600;
  text-align: left;
}

.product-detail-sec .product-detail-right .pdt-detail-title .client-logo {
  height: 50px;
  cursor: pointer;
  margin-right: 20px;
  object-fit: contain;
  width: 128px;
}

.product-detail-sec .product-detail-right .pdt-detail-title p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
}

.product-detail-sec .product-detail-right .pdt-detail-title p img {
  height: 14px;
  margin-right: 8px;
}

.product-detail-sec .product-detail-right .pdt-detail-subtitle {
  letter-spacing: 0px;
  color: #26477F;
  text-transform: capitalize;
  opacity: 1;
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px;
}

.product-detail-sec .product-detail-right .gray-text {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.59;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.product-detail-sec .product-detail-right .pdt-description {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item {
  border-bottom: 1px solid #DCDEE4;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-button:not(.collapsed)::after {
  content: "-";
  font-size: 30px;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-button::after {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  font-size: 20px;
  line-height: 14px;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item .accordion-header button {
  letter-spacing: 0px;
  color: #000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 600;
  font-size: 17px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item .accordion-header {
  margin-bottom: 0;
  margin: 12px 0;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul {
  padding: 6px 15px;
  overflow-y: auto;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul li h5 {
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 0.99;
  color: #000;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul li {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin: 12px 0;
  list-style: none;
  /* min-width: 100%;
  max-width: 100%; */
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul li p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 0px;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-body ul li p span {
  letter-spacing: 0.22px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
}

.accordion .accordion-item:last-child .show {
  border-bottom: none !important;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item .show {
  border-bottom: 2px solid #254771;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item ul li.submital-data {
  flex-direction: row;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item ul.file-ul {
  display: flex;
  flex-wrap: wrap;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .accordion-item ul.file-ul li {
  margin: 4px 0;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ECECED;
  opacity: 1;
  background: #fff;
  /* width: 450px; */
  padding: 8px;
  height: 45px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec p {
  margin-bottom: 0;
  letter-spacing: 0.19px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec a {
  display: flex;
  text-decoration: none;
  align-items: center;
  /* justify-content: center; */
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec a p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 /* width: 142px; */
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec img {
  height: 16px;
  margin-right: 10px;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec span {
  padding: 10px;
  border-left: 1px solid #ECECED;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-detail-sec .product-detail-right .pdt-features .accordion .download-sec span img {
  margin-right: 0;
  height: 14px;
  margin-left: 7px;
}

.product-detail-sec .product-detail-right .pdt-features {
  border-top: 1px solid #ECECED;
  margin: 40px 0;
  padding: 20px 0;
}

.product-detail-sec .our_products-sec.related-product .sec-title {
  font-size: 24px;
  margin-bottom: 0;
}
.product-detail-sec .our_products-sec.related-product .related-carouselItem{
  margin-top: 25px;
}
.our_products-sec .related-carouselItem a{
  text-decoration: none;
  color: inherit;
}
.our_products-sec .related-carouselItem .carousel__caption p {
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  font-size: 15px;
  margin: 10px 0;
  color: #000;
  line-height: 24px;
}
.our_products-sec .related-carouselItem .carousel__item .image-box{
  background: #f5f7fa;
  padding: 30px;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.our_products-sec .related-carouselItem .carousel__item .image-box img {
  height: 210px;
  object-fit: contain;
  width: 100%;
}

.our_products-sec .related-carouselItem .carousel__item .image-box img{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.our_products-sec .related-carouselItem .carousel__item .image-box:hover{
  background: transparent linear-gradient(1deg, #18a6ef1f 0%, #e0eef682 100%);
}
.our_products-sec .related-carouselItem .carousel__item .image-box:hover img{
  -webkit-transform: scale(1.1);
	transform: scale(1.1);
}


.product-detail-sec .our_products-sec.product-detail .mobile-share-icon {
  display: none;
  position: relative;
  right: 36px;
  float: right;
  z-index: 99;
  top: 10px;
}

.product-detail-sec .our_products-sec.product-detail .mobile-share-icon img {
  height: 28px;
}

.product-detail-sec .our_products-sec.related-product .product-carouselItem {
  display: none;
}
.product-detail-sec .our_products-sec.related-product .bottom-more{
  display: none;
  margin-top: 40px;
}
.product-detail-sec .our_products-sec.related-product .bottom-more .button-more a{
  background-color: #000;
  color: #fff;
}

#popover-positioned-right {
  padding: 8px 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #25477121;
  opacity: 1;
  border: 1px solid rgba(0,0,0,.2);
  /* transform: translate3d(1171.33px, 139.333px, 0px) !important; */
}
#popover-positioned-right .arrow::before {
  left: 0;
  border-right-color: rgba(0,0,0,.25);
}
#popover-positioned-right .arrow:after {
  left: 1px;
  border-right-color: #fff;
}
#popover-positioned-right .arrow::before,
#popover-positioned-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
#popover-positioned-right .social-share{
  display: flex;
}
#popover-positioned-right .social-share a{
  margin: 2px;
}
#popover-positioned-right .social-share a[title="Instagram"] img{
  height: 12px;
  width: 12px;
}
#popover-positioned-right .social-share a[title="WhatsApp"] img{
  height: 16px;
  width: 16px;
}
#popover-positioned-right .social-share img{
  height: 13px;
  width: 13px;
  object-fit: contain;
}
.popover>.arrow, .popover>.arrow:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.popover.right>.arrow:after {
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -18px;
  content: " ";
  left: 1px;
}
.popover>.arrow:after {
  border-width: 10px;
  content: "";
}
.popover.right>.arrow {
  border-left-width: 0;
  left: -13px;
  margin-top: -11px;
  top: 50%;
}
#popover-positioned-bottom {
  padding: 10px;
  margin-top: 0px;
  border: 1px solid #f1f1f1;
  z-index: 9;
}
#popover-positioned-bottom .social-share{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#popover-positioned-bottom .social-share a img{
  height: 12px;
}
#popover-positioned-bottom .social-share a[title="WhatsApp"] img{
  height: 15px;
}
#popover-positioned-bottom >.arrow::before{
  border-bottom-color: #fff;
}
.Mobile-request-quote .back-btn {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
}

.Mobile-request-quote {
  display: none;
}

.Mobile-request-quote .Form-field,
.Mobile-request-quote .File-field {
  padding: 15px;
  margin-top: 20px;
}

.Mobile-request-quote .back-btn svg {
  width: 15px;
  margin-right: 4px;
}

.Mobile-request-quote .our_products-sec.request-quote .sec-title {
  font-size: 25px;
}

.Mobile-request-quote .our_products-sec.request-quote .sec-bottom {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
}

.Mobile-request-quote .our_products-sec.request-quote .sec-bottom-title {
  font-size: 18px;
  line-height: 34px;
}

.Mobile-request-quote .our_products-sec.request-quote {
  padding: 20px 15px;
}

.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-label {
  font-size: 15px;
  font-weight: 500;
  color: #898989;
  top: 0;
  z-index: 9;
}
.Mobile-request-quote .our_products-sec.request-quote .contact-form #react-select-5-placeholder{
  font-size: 18px;
    font-weight: 500;
    color: #898989;
    top: 0;
    z-index: 9;
}
.Mobile-request-quote .our_products-sec.request-quote .contact-form #react-select-5-listbox{
  z-index: 9999;
}
.new-demo-modal .modal-dialog{
  max-width: 600px !important;
}
.new-demo-modal.otp-modal .modal-dialog{
  max-width: 520px !important;
}
.new-demo-modal.otp-modal .modal-dialog .contact-form  .did-floating-label-content{
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.new-demo-modal.otp-modal .modal-dialog .contact-form  .did-floating-label-content .did-floating-input {
  border-bottom: 1px solid #898989;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.new-demo-modal .contact-form .did-floating-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #898989;
  top: 11px;
  z-index: 9;
}
.new-demo-modal.success-modal .modal-dialog{
  max-width: 490px !important;
}
.new-demo-modal.fail-modal .modal-dialog{
  max-width: 524px !important;
}
.new-demo-modal.success-modal .modal-dialog h1,
.new-demo-modal.fail-modal .modal-dialog h1{
  font-size: 28px;
  font-weight: 500;
  color: #000;
  text-align: center;
  opacity: 1;
  margin: 20px 0;
}
.new-demo-modal.success-modal .modal-dialog  .modal-body p,
.new-demo-modal.fail-modal .modal-dialog  .modal-body p{
  text-align: center;
  font-size: 15px !important;
}
.new-demo-modal.success-modal .modal-dialog  .modal-body .body-box,
.new-demo-modal.fail-modal .modal-dialog  .modal-body .body-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.new-demo-modal.success-modal .modal-body .submit-btn{
  background: #53AA68 !important;
  width: 180px !important;
}
.new-demo-modal.fail-modal .modal-body .submit-btn{
  background: #D53030 !important;
  width: 180px !important;
}

.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input:focus~.did-floating-label {
  font-size: 15px;
  color: #46554D;
  font-weight: 500;
  top: -16px !important;
}

.new-demo-modal .contact-form .did-floating-input:focus~.did-floating-label {
  font-size: 15px !important;
  font-weight: 400 !important;
  top: -15px !important;
  color: #46554D;
}

.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input,
.new-demo-modal .contact-form .did-floating-input {
  border-bottom: 1px solid #81868c38;
}
.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input:focus,
.new-demo-modal .contact-form .did-floating-input:focus,
.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input[value]:not([value=""]),
.new-demo-modal .contact-form .did-floating-input[value]:not([value=""])
 {
  border-bottom: 1.2px solid #000000;
}
.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input:focus,
.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input[value]:not([value=""]){
  font-size: 15px;
  font-weight: 500;
}
.new-demo-modal .contact-form .did-floating-input:focus
.new-demo-modal .contact-form .did-floating-input:-internal-autofill-selected{
  background-color: transparent !important;
}
.new-demo-modal .contact-form .did-floating-input, .new-demo-modal .contact-form .PhoneInput .PhoneInputInput {
  font-size: 14px;
  font-weight: 500;
  color: #323840;
}
.new-demo-modal .contact-form  #react-select-9-placeholder, .new-demo-modal .contact-form #react-select-7-placeholder{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #898989;
  margin-bottom: -5px;
}
.new-demo-modal .contact-form .did-floating-input .css-13cymwt-control, 
.Mobile-request-quote .contact-form .did-floating-input .css-13cymwt-control{
  border: none;
  border-bottom: 1px solid #81868c38;
  border-radius: 0;
}
.new-demo-modal .contact-form .did-floating-input .css-13cymwt-control .css-1u9des2-indicatorSeparator,
.new-demo-modal .contact-form .did-floating-input .css-t3ipsp-control .css-1u9des2-indicatorSeparator,
.Mobile-request-quote .contact-form .did-floating-input  .css-1u9des2-indicatorSeparator{
  display: none;
}
.new-demo-modal .contact-form .did-floating-input .css-t3ipsp-control,
.Mobile-request-quote .contact-form .did-floating-input .css-t3ipsp-control{
  border-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #81868C;
  border-radius: 0;
}
.new-demo-modal .contact-form .did-floating-input .css-1fdsijx-ValueContainer,
.Mobile-request-quote .contact-form .did-floating-input .css-1fdsijx-ValueContainer{
  padding-left: 0;
}
.new-demo-modal .contact-form .did-floating-input .css-1dimb5e-singleValue,
.Mobile-request-quote .contact-form .did-floating-input .css-1dimb5e-singleValue{
  color: #323840;
}
.Mobile-request-quote .contact-form .did-floating-input .css-1dimb5e-singleValue{
  font-size: 15px;
  font-weight: 500;
}
.new-demo-modal .contact-form .did-floating-input  #react-select-3-placeholder{
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #898989;
}
.Mobile-request-quote .contact-form .did-floating-input  #react-select-3-placeholder{
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #898989;
}
.new-demo-modal .contact-form .did-floating-input.css-b62m3t-container,
.Mobile-request-quote .contact-form .did-floating-input.css-b62m3t-container{
  z-index: 99;
}
.Mobile-request-quote .our_products-sec.request-quote .contact-form .did-floating-input:not(:placeholder-shown)~.did-floating-label{
  top: -16px;
  font-size: 15px;
}
.new-demo-modal .contact-form .did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -16px;
  font-size: 17px;
}
.new-demo-modal .contact-form .did-floating-input.css-b62m3t-container .css-hlgwow
{
  padding: 0;
}

.Mobile-request-quote .our_products-sec.request-quote .contact-form .submit-btn {
  padding: 10px;
  background-color: #00A6EF;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  box-shadow: 0px 0px 2px 0px #00000099;
  display: flex;
  height: 55px;
  border-radius: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  z-index: 999;
}

.Mobile-request-quote .our_products-sec.request-quote .contact-form .submit-btn img {
  margin-left: 10px;
}

.Mobile-request-quote .our_products-sec.request-quote .form-container,
.new-demo-modal .form-container {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mobile-request-quote .our_products-sec.request-quote .upload-files-container,
.new-demo-modal .upload-files-container {
  background-color: #25477107;
  width: 100%;
  padding: 30px 60px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 2px dashed #25477126; */
  height: 380px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2325477126' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.new-demo-modal .upload-files-container {
  height: 210px;
}
.new-demo-modal .upload-files-container .uploaded-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-demo-modal .upload-files-container .uploaded-box .close-img{
  margin-left: -10px;
  background: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  border: none;
  margin-top: -42px;
  height: 22px;
  width: 22px;
  outline: none;
  box-shadow: none;
  right: 60px;
  position: unset !important;
}
.new-demo-modal  .upload-files-container .uploaded-box a{
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: #000;
  font-size: 14px;
  text-decoration: none;
}
.new-demo-modal .upload-files-container .uploaded-box a img{
  height: 14px;
  margin-right: 10px;
}

.Mobile-request-quote .our_products-sec.request-quote .drag-file-area,
.new-demo-modal .drag-file-area {
  border-radius: 40px;
  margin: 40px 0 15px;
  /* padding: 30px 50px; */
  text-align: center;
}

.Mobile-request-quote .our_products-sec.request-quote .drag-file-area .upload-icon,
.new-demo-modal .drag-file-area .upload-icon {
  font-size: 50px;
}

.Mobile-request-quote .our_products-sec.request-quote .drag-file-area h3,
.new-demo-modal .drag-file-area h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 5px 0;
  color: #000000;
}

.Mobile-request-quote .our_products-sec.request-quote .drag-file-area label,
.new-demo-modal .drag-file-area label {
  font-size: 19px;
  color: #7A818B;
}

.Mobile-request-quote .our_products-sec.request-quote .drag-file-area label .browse-files-text,
.new-demo-modal .drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}

.Mobile-request-quote .our_products-sec.request-quote .browse-files span,
.new-demo-modal .browse-files span {
  position: relative;
  top: -25px;
}

.Mobile-request-quote .our_products-sec.request-quote .default-file-input,
.new-demo-modal .default-file-input {
  opacity: 0;
  width: 100%;
}
.Mobile-request-quote .drag-file-area img {
  height: 58px;
}
.Mobile-request-quote  .upload-files-container .uploaded-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Mobile-request-quote  .upload-files-container .uploaded-box .close-img{
  background: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  border: none;
  height: 22px;
  width: 22px;
  outline: none;
  box-shadow: none;
  bottom: 30px;
  left: 9px;
  position: relative;
}
.Mobile-request-quote  .upload-files-container .uploaded-box a{
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: #000;
  font-size: 14px;
  text-decoration: none;
}
.Mobile-request-quote  .upload-files-container .uploaded-box a img{
  height: 14px;
  margin-right: 10px;
}

.new-demo-modal .drag-file-area img {
  height: 30px;
}
.new-demo-modal .drag-file-area img.uploaded-img{
  height: 140px;
}
.new-demo-modal .drag-file-area .close-img,
.Mobile-request-quote .drag-file-area .close-img{
  position: absolute;
  margin-left: -25px;
  background: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  border: none;
  margin-top: 4px;
  height: 22px;
  width: 22px;
  outline: none;
  box-shadow: none;
}
.Mobile-request-quote .File-field .submit-btn {
  padding: 10px;
  background-color: #00A6EF;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  box-shadow: 0px 0px 2px 0px #00000099;
  height: 55px;
  border-radius: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.new-demo-modal .modal-footer {
  border: none;
  padding-bottom: 25px;
  justify-content: flex-start;
}

.new-demo-modal .modal-footer .submit-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 40px;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.new-demo-modal .modal-footer .back-btn {
  background: #fff;
  padding: 15px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #000;
  height: 40px;
  margin-right: 15px;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.new-demo-modal .modal-footer .back-btn img {
  margin-right: 8px;
}

.new-demo-modal .modal-content {
  border-radius: 0;
}

.new-demo-modal .modal-header {
  padding-bottom: 0;
  border-bottom: 0;
}
.new-demo-modal .modal-header .modal-title{
  font-size: 22px;
  font-weight: 500;
  color: #000;
  text-align: left;
  opacity: 1;
}

.new-demo-modal .modal-header .btn-close {
  background: none;
  outline: none;
  box-shadow: none !important;
  border: none !important;
}

.new-demo-modal .modal-body p {
  letter-spacing: 0.19px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  position: relative;
  bottom: 10px;
}

.new-demo-modal .modal-body .submit-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  margin-right: 12%;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 120px;
  margin-top: 12px;
  margin-bottom: 2px;
}

/*- Product detail page css ends -*/

/*- download-center page css -*/
.download-center .our_products-sec.downloadcenter {
  background-image: url(../images/download-center-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
}

.download-center .our_products-sec.downloadcenter .sec-bottom .sec-title {
  max-width: 800px;
  line-height: 42px;
  color: #fff;
}

.download-center .our_products-sec.downloadcenter .sec-top-title {
  color: #fff;
}

.download-center .our_products-sec.searchproduct {
  padding: 20px 80px 50px;
  background: #F5F7FA;
}

.download-center .our_products-sec.searchproduct .product-filter {
  margin-bottom: 20px;
}

.download-center .our_products-sec.searchproduct .product-filter form.nosubmit {
  border: none;
  padding: 0;
}

.download-center .our_products-sec.searchproduct .pdt-name-top {
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: left;
  margin-top: 15px;
}

.download-center .our_products-sec.searchproduct .product-filter input.nosubmit {
  width: 100%;
  padding: 8px 4px 8px 28px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 0px center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #DCDEE4;
  border-radius: 0px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.download-center .our_products-sec.searchproduct .product-filter input.nosubmit::placeholder{
  font-weight: 400;
}
.download-center .our_products-sec.searchproduct .product-filter input.nosubmit::placeholder {
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 0.33;
}

.download-center .our_products-sec.searchproduct .product-filter .filter-sec {
  background: #FFFFFF;
  padding: 15px 10px;
  height: 500px;
  overflow: auto;
}

.download-center .our_products-sec.searchproduct .product-filter h4 {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}

.download-center .our_products-sec.searchproduct .filter-category {
  margin: 20px 0;
}

.download-center .our_products-sec.searchproduct .filter-category h4 {
  font-size: 15px !important;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion {
  margin: 20px 0;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item {
  border-bottom: 1px solid #DCDEE4;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-button:not(.collapsed)::after {
  content: "-";
  font-size: 30px;
}

.download-center .our_products-sec.searchproduct .accordion .accordion-button::after {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  font-size: 20px;
  line-height: 14px;
  color: #000;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item .accordion-header button {
  letter-spacing: 0px;
  color: #0B2A60;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 400;
  font-size: 14px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  padding-left: 0;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item .accordion-header {
  margin-bottom: 0;
  margin: 8px 0 8px;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item .header-title {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  /* margin-bottom: 0;
  margin-top: 15px; */
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body ul {
  padding: 6px 15px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body .type-text {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body .type-text h5 {
  letter-spacing: 0px;
  color: #617088;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body .type-text a {
  letter-spacing: 0px;
  color: #095291;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body ul li {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  align-items: center;
  margin: 12px 0;
  list-style: none;
  white-space: nowrap;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body ul li label {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-body ul li input[type="checkbox"] {
  accent-color: #095291;
  height: 15px;
  width: 15px;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item:last-child .show {
  border-bottom: none;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item .show {
  border-bottom: 1px solid #085291;
}

.download-center .our_products-sec.searchproduct .product-filter .accordion .accordion-item .accordion-header button[aria-expanded="true"] {
  color: #095291 !important;
}

.download-center .right-side .table {
  display: block;
  /* overflow-x: auto; */
  white-space: nowrap;
  height: 450px;
  overflow: auto;
}
.download-center .right-side .table thead th {
  background: #00A6EF;
  border: none !important;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding: 8px;
  position: sticky;
  top: 0;
  padding-left: 50px;
}
.download-center .right-side .table tbody tr td{
  padding-left: 50px;
}
.download-center .right-side .table tbody tr:nth-child(even) td {
  background: #F7F7F7;
}

.download-center .right-side .table tbody tr:nth-child(odd) td {
  background: #fff;
}

.download-center .right-side .table tbody tr td,
.mobile-searchproduct .download-box p {
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  text-transform: inherit;
  white-space: nowrap;
  border: none;
}

.download-center .right-side .table tbody tr td .title,
.mobile-searchproduct .download-box .title {
  color: #0B2A60;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  white-space: break-spaces;
}

.download-center .pagination-sec .pagination-d-flex nav{
  position: absolute;
  bottom: 11px;
  margin: 0;
}
.download-center .pagination-sec .pagination-d-flex .form-control{
  width: auto!important;
  outline: none !important;
  box-shadow: none !important;
}
.download-center .pagination-sec .pagination-d-flex .form-control:focus{
  border-color: #ced4da !important;
}
.download-center .pagination-sec .pagination-d-flex  .align-items-center p{
  margin: 0;
}

.mobile-searchproduct.our_products-sec {
  padding: 50px 15px;
  background: #F5F7FA;
}

.mobile-searchproduct.our_products-sec .download-box {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 15px;
  overflow-x: auto;
}

.mobile-searchproduct .download-box .title,
.mobile-searchproduct .download-box p {
  white-space: unset;
}

.mobile-searchproduct .download-box p {
  font-size: 18px !important;
}

.mobile-searchproduct .download-box .download-text {
  display: flex;
  margin-left: 24px;
}

.mobile-searchproduct .download-box .download-text p:first-child {
  white-space: nowrap;
  margin-right: 15px;
  min-width: 150px;
}

.mobile-searchproduct .download-box .title p {
  color: #0B2A60 !important;
  white-space: unset;
}

.download-center .right-side .table tbody tr td .title p {
  max-width: 250px;
  width: 250px;
}

.download-center .our_products-sec.mobile-searchproduct {
  display: none;
}

.download-center .right-side .table tbody tr td .title img,
.mobile-searchproduct .download-box .title img {
  height: 16px;
  margin-right: 10px;
  cursor: pointer;
}

.download-center .right-side .table tbody tr td .download,
.mobile-searchproduct .download-box .download {
  display: flex;
  letter-spacing: 0px;
  color: #005795;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.mobile-searchproduct .download-box .download {
  justify-content: center;
}

.mobile-searchproduct .download-box .download p {
  color: #3177a9 !important;
  white-space: unset !important;
}
.download-center .right-side .table tbody tr td .download a,
.mobile-searchproduct .download-box .download a{
  color: inherit;
}
.mobile-searchproduct .download-box .download img,
.mobile-searchproduct .download-box .title img {
  height: 18px !important;
}

.download-center .right-side .table tbody tr td .download img,
.mobile-searchproduct .download-box .download img {
  height: 14px;
  margin-right: 10px;
  margin-top: 3px;
}

.our_products-sec.mobile-searchproduct .fixedDiv .filter {
  background-position: unset;
  background: transparent url("../images/filter.png") no-repeat 8px center;
  padding-left: 28px;
}


/*- download-center page css ends-*/

/*- Reference page css-*/

.our-reference .our_products-sec.reference .sec-bottom .form-control {
  width: 320px;
  background: #F8FAFB;
  border: 1px solid #DCDEE4;
  border-radius: 4px;
  opacity: 1;
  height: 34px;
  box-shadow: none !important;
  outline: none !important;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}

.our-reference .our_products-sec.reference .reference-list {
  margin-top: 20px;
}

.our-reference .our_products-sec.reference .reference-list .sec-bottom-title {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.41;
  font-size: 14px;
  font-weight: 400;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .carousel__caption h5 {
  letter-spacing: 0.23px;
  color: #212121;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 10px;
  text-transform: math-auto;
}
.our-reference .our_products-sec.reference .reference-list .carousel__item .carousel__caption h5::first-letter{
  text-transform: uppercase;
}
.our-reference .our_products-sec.reference .reference-list .carousel__item .carousel__caption p {
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}

.our-reference .our_products-sec.reference .reference-list .load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.our-reference .our_products-sec.reference .reference-list .carousel__item .image-box {
  height: 200px;
  overflow: hidden;
  display: flex;
}
.our-reference .our_products-sec.reference .reference-list .carousel__item img{
  width: 100%;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item:hover .text img {
  opacity: 0.8;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item:hover .middle {
  opacity: 1;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .text {
  background-color: #000;
  color: white;
  font-size: 16px;
  padding: 20px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .text img {
  height: 15px;
  min-height: auto;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .text p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  opacity: 0.8;
  margin-top: 6px;
}

.our-reference .our_products-sec.reference .reference-list .carousel__item .middle h2 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 18px;
  text-align: center;
  text-transform: math-auto;
}
.our-reference .our_products-sec.reference .reference-list .carousel__item .middle h2::first-letter{
  text-transform: uppercase;
}
.reference-detail .refer-detail{
   height: 275px;
}
.reference-detail .refer-detail img{
  min-height: 0;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  align-items: end;
  display: flex;
}

.reference-detail .detail-right {
  padding: 84px 28px;
}

.reference-detail .our_products-sec.ref-detail .sec-title {
  width: max-content;
  line-height: 42px;
}

.reference-detail .detail-right h4 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #959595;
  opacity: 1;
}

.reference-detail .detail-right p {
  letter-spacing: 0.44px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.reference-detail .related-carouselItem {
  margin-top: 20px;
}
.reference-detail .our_products-sec.ref-carousel{
  padding: 50px 0 50px 80px;
}

.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .carousel__item{
  margin: 0 118px;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multi-carousel-item--active .carousel__item{
  margin: 0 -24px!important;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multi-carousel-item--active{
  margin: 0 -118px;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multi-carousel-item:last-child:not(:first-child).react-multi-carousel-item--active .carousel__item {
  margin: 0 100px!important;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multi-carousel-item:last-child.react-multi-carousel-item--active{
  margin: 0 42px;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-item:not(.react-multi-carousel-item--active){
  opacity: 0.61;
}



.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right {
  right: calc(9.7% + 1px);
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list  .carousel__item{
  height: 100vh;
  width: 100%;
}
.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list  .carousel__item img{
  width: 100%;
  height: 100%;
}
.reference-detail .our_products-sec.ref-carousel .react-multiple-carousel__arrow {
  background: #000000;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  z-index: 9;
}

.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right::before {
  background-image: url(../images/next-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.reference-detail .our_products-sec.ref-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left::before {
  background-image: url(../images/prev-button-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.reference-detail .our_products-sec.latestNews {
  background: #EDF8FF;
}

.reference-detail .our_products-sec.latestNews .latest-news-sec .news-section h5 {
  margin-bottom: 5px;
  text-decoration: none !important;
}
.reference-detail .our_products-sec.latestNews .latest-news-sec a{
  text-decoration: none;
}
.reference-detail .our_products-sec.latestNews .latest-news-sec .news-section p {
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  font-size: 14px;
}

.reference-detail .refernce-non-carousel,
.reference-detail .product-carouselItem {
  display: none;
}


.reference-detail .related-product .pdt-supplied-carousel{
  background: #fff;
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right::before {
  background-image: url(../images/next-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left::before {
  background-image: url(../images/prev-button-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item,
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item{
  margin: 0 5px;
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item img {
  width: 100%;
  height: 210px;
  object-fit: contain;
  /* background: #f5f7fa; */
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .image-box{
  background: #f5f7fa;
  padding: 30px;
  height: 100%;
  margin: 0;
  /* padding: 0;
  background: #fff; */
  overflow: hidden;
}

.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .image-box img{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .image-box:hover{
  background: transparent linear-gradient(1deg, #18a6ef1f 0%, #e0eef682 100%);
}
.reference-detail .related-product .pdt-supplied-carousel .react-multi-carousel-list ul .react-multi-carousel-item .image-box:hover img{
  -webkit-transform: scale(1.1);
	transform: scale(1.1);
}


/*- Reference page css ends-*/

/*- Conact us page css -*/
.Contact-Us .our_products-sec.contact-us {
  background-image: url(../images/contact-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
}

.Contact-Us .our_products-sec.contactus {
  padding: 50px 80px !important;
}

.Contact-Us .our_products-sec.contact-us .sec-title {
  color: #fff;
}

.Contact-Us .our_products-sec.contactus .sec-title {
  max-width: 100%;
}

.Contact-Us .our_products-sec.contactus {
  background: transparent !important;
}

.Contact-Us .form-section .expert-box h4 {
  letter-spacing: 0px;
  color: #26477F;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
}

.Contact-Us .form-section {
  margin-top: 38px;
}

.Contact-Us .form-section .expert-box {
  margin-bottom: 40px;
}

.Contact-Us .form-section .expert-box .expert-phn {
  display: flex;

}

.Contact-Us .form-section .expert-box .expert-phn p {
  margin-right: 10px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid#000000;
  padding-right: 10px;
  margin-bottom: 10px;
}

.Contact-Us .form-section .expert-box .expert-phn p:last-child {
  border-right: 0;
}

.Contact-Us .form-section .expert-box p.date {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
}

.Contact-Us .form-section .contact-form .form-check input {
  height: 16px;
  width: 16px;
  accent-color: #26477F;
}
.agree-check{
  display:flex;
  align-items: baseline;
}
.Contact-Us .form-section .contact-form .form-check .form-error{
  margin-left: -20px;
}
.Contact-Us .form-section .contact-form .form-check label {
  margin-left: 10px;
}

.Contact-Us .our_products-sec.our-offices .address-book {
  margin-top: 40px;
}

.Contact-Us .our_products-sec.our-offices .address-book .office-location {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: left;
  font-size: 21px;
  font-weight: 500;
  /* width: 148px; */
}

.Contact-Us .our_products-sec.our-offices .address-book .office-address h4 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.Contact-Us .our_products-sec.our-offices .address-book .office-address p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.Contact-Us .our_products-sec.our-offices .address-book .office-address p.tel{
  margin-top: 15px;
}
.Contact-Us .our_products-sec.our-offices .address-book .office-address {
  border-bottom: 2px solid #000000;
  padding-bottom: 15px;
  /* max-height: 260px; */
  min-height: 84%;
  max-height: 100%;
  margin-bottom: 50px;
}

.Contact-Us .our_products-sec.our-offices .address-book .office-address .address-one span {
  font-weight: 600;
}

.Contact-Us .contact.counter-section {
  background: #0B2A60;
  padding: 50px 80px;
  color: #fff;
  height: 550px;
}

.Contact-Us .contact.counter-section .our_products-sec {
  padding: 0px 0px;
}

.Contact-Us .contact.counter-section .our_products-sec .sec-title {
  color: #fff;
  margin-bottom: 10px;
}

.Contact-Us .contact.counter-section .our_products-sec p.country-names {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
}
.Contact-Us .contact.counter-section .our_products-sec .contact-location{
  margin: 50px 0 0; 
}
.Contact-Us .contact.counter-section .our_products-sec p.country-names span {
  letter-spacing: 0px;
  color: #345A9D;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.Contact-Us .contact.counter-section .our_products-sec .contact-location .showHide,
.Contact-Us .contact.counter-section .our_products-sec .contact-location .showLocationBox
{
  display: none;
}
.Contact-Us .contact.counter-section .our_products-sec .contact-location .mark-country{
  fill: #1359d3;
}
.Contact-Us .contact.counter-section .our_products-sec .contact-location .mark-country.showSaudi{
  fill: #9dc0ff;
}
.Contact-Us .contact.counter-section .our_products-sec p.country-names a span.active {
  text-decoration: underline !important;
  color: #fff !important;
}

.Contact-Us .contact.counter-section .our_products-sec .mapview {
  margin: 50px 45px;
  height: 300px;
  cursor: pointer;
}

.Contact-Us .contact.counter-section .counter-text {
  letter-spacing: 0.04px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  width: 252px;
}

.Contact-Us .contact.counter-section .counter-field {
  margin: 50px 0px;
}
.Contact-Us .contact.counter-section .counter-field h4{
  font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: 0;
    color: #fff;
}
.Contact-Us .contact.counter-section .counter-field .count-box .counter-number {
  letter-spacing: 0.16px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0px;
}

.Contact-Us .contact.counter-section .counter-field .count-box .counter-label {
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.Contact-Us .contact.counter-section .counter-field .count-box {
  margin-bottom: 40px;
}

.Contact-Us .contact.counter-section .knowmore-btn {
  background: transparent;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  border-radius: 0;
  height: 45px;
  width: max-content;
  margin-top: 0px;
  text-decoration: none;
}

.Contact-Us .contact.counter-section .knowmore-btn img {
  height: 14px;
}
.alert-toast{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
  margin-top: 1rem;
  /* width: max-content; */
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 12%);
}
.alert-toast p{
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  text-transform: inherit;
  bottom:0 !important;
}
.alert-toast .close-img {
  margin-left: 8px;
  background: #fff;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #f5f5f5;
  margin-top: -38px;
  height: 19px;
  padding: 1px 5px;
  width: 19px;
  outline: none;
  position: absolute;
  right: 8px;
  border-radius: 50%;
}
.alert-toast .close-img:hover{
  background: #aa182c;
  color: #fff;
}

/*- Conact us page css ends-*/

/*- Career page css-*/

.Careers .our_products-sec.careers {
  background-image: url(../images/career-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  align-items: end;
  display: flex;
}

.Careers .our_products-sec.careers .sec-title {
  color: #fff;
}

.Careers .our_products-sec.careers-sec .sec-title {
  width: 593px;
  line-height: 42px;
}

.Careers .our_products-sec.careers-sec .sec-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 65px;
  float: right;
  position: relative;
  right: calc(0% + 48px);
}

.Careers .our_products-sec.careers-sec .sec-bottom-title {
  max-width: 685px;
  text-align: justify;
  /* margin-left: 160px; */
}

.Careers .our_products-sec.careers-sec .submit-btn {
  background: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0;
  margin-top: 15px;
}
.Careers .our_products-sec.careers-sec .submit-btn a{
  color: inherit;
  text-decoration: none;
}
.Careers .our_products-sec.careers-sec {
  display: flow-root;
}
.Careers .our_products-sec.careers-sec{
  padding: 50px 80px 50px 128px;
}
.Careers .our_products-sec.careers-sec .life-at h4 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.Careers .our_products-sec.careers-sec .life-at .react-multi-carousel-list .carousel__item img {
  height: 250px;
}

.Careers .our_products-sec.careers-sec .life-at .react-multi-carousel-list .carousel__item {
  margin-right: 10px;
}

.Careers .our_products-sec.careers-sec .life-at .react-multi-carousel-list .react-multi-carousel-dot-list {
  justify-content: flex-start;
}

.Careers .our_products-sec.learning {
  background: #F8F8F8;
  padding: 50px 128px 50px 128px;
}

.Careers .our_products-sec.learning .sec-title {
  width: 360px;
  margin-bottom: 25px;
  margin-top: 80px;
}

.Careers .our_products-sec.learning .right-sec img {
  height: 370px;
  float: right;
}

.Careers .our_products-sec.careers-sec .react-multiple-carousel__arrow {
  background: #000000;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  z-index: 9;
}

.Careers .our_products-sec.careers-sec .react-multi-carousel-list .react-multiple-carousel__arrow--right::before {
  background-image: url(../images/next-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.Careers .our_products-sec.careers-sec .react-multi-carousel-list .react-multiple-carousel__arrow--left::before {
  background-image: url(../images//prev-button-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 14px;
  content: "" !important;
}

.Careers .benefits-sec {
  background-image: url(../images/blue-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 450px; */
}

.Careers .our_products-sec.benefits {
  padding: 38px 80px;
}

.Careers .our_products-sec.benefits .sec-title {
  color: #fff;
  width: 368px;
  line-height: 42px;
}
.Careers .our_products-sec.benefits .counter-field{
  margin-top: 64px;
}
.Careers .our_products-sec.benefits .sec-top-title {
  color: #fff;
}

.Careers .our_products-sec.benefits .counter-field .counter-number {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
}

.Careers .our_products-sec.benefits .counter-field .count-box img {
  margin: 25px 30px;
}

.Careers .our_products-sec.benefits .counter-field .counter-label {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.56px;
  color: #fff;
  margin-bottom: 20px;
}

.Careers .our_products-sec.benefits .counter-field .count-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #ffffff1c;
  padding-top: 15px;
}

.Careers .our_products-sec.benefits .counter-field .count-box .counter-desc {
  color: #fff;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 400;
}

.Careers .our_products-sec.benefits .counter-field .count-box .desc-div {
  margin: 15px 5px 15px 25px;
}

.Careers .our_products-sec.career-filter .sec-title {
  width: 250px;
}
.Careers .our_products-sec.career-filter{
  background: #fff;
  position: relative;
  z-index: 0;
}

.Careers .our_products-sec.career-filter .product-filter,
.Careers .Filter-field .product-filter{
  margin: 20px 0;
}
.Careers .Filter-field{
  margin-top: 40px;
  display: none;
}
.Careers .Filter-field .back-btn {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
}

.Careers .Filter-field .back-btn svg {
  width: 15px;
  margin-right: 4px;
}

.Careers .our_products-sec.career-filter .product-filter form.nosubmit,
.Careers .Filter-field .product-filter form.nosubmit{
  border: none;
  padding: 0;
}

.Careers .our_products-sec.career-filter .product-filter input.nosubmit,
.Careers .Filter-field .product-filter input.nosubmit{
  width: 100%;
  padding: 8px 4px 8px 35px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  background-color: transparent;
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  color: #000;
}
.Careers .our_products-sec.career-filter .product-filter input.nosubmit::placeholder{
  font-size: 14px !important;
}

.Careers .our_products-sec.career-filter .product-filter input.nosubmit::placeholder,
.Careers .Filter-field .product-filter input.nosubmit::placeholder{
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 0.33;
}

.Careers .our_products-sec.career-filter .product-filter .filter-sec,
.Careers .Filter-field  .product-filter .filter-sec{
  background: #FFFFFF;
  padding: 15px 10px;
}

.Careers .our_products-sec.career-filter .product-filter h4, .Careers .Filter-field .product-filter h4{
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #70707061;
}

.Careers .our_products-sec.career-filter .filter-category,  .Careers .Filter-field .filter-category {
  margin: 20px 0;
}

.Careers .our_products-sec.career-filter .filter-category h4,  .Careers .Filter-field .filter-category h4 {
  font-size: 16px !important;
}

.Careers .our_products-sec.career-filter .product-filter .accordion,  .Careers .Filter-field .product-filter .accordion {
  margin: 20px 0;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item,
.Careers .Filter-field .product-filter .accordion .accordion-item {
  border-bottom: 1px solid #70707061;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-button:not(.collapsed)::after,
.Careers .Filter-field .product-filter .accordion .accordion-button:not(.collapsed)::after{
  content: "-";
  font-size: 30px;
}

.Careers .our_products-sec.career-filter .accordion .accordion-button::after,
.Careers .Filter-field .accordion .accordion-button::after{
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  font-size: 20px;
  line-height: 14px;
  color: #000;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item .accordion-header button,
.Careers .Filter-field .product-filter .accordion .accordion-item .accordion-header button{
  letter-spacing: 0px;
  color: #095291;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  padding-left: 0;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item .accordion-header,
.Careers .Filter-field .product-filter .accordion .accordion-item .accordion-header{
  margin-bottom: 0;
  margin: 10px 0;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item .header-title,
.Careers .Filter-field .product-filter .accordion .accordion-item .header-title{
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 15px;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body ul,
.Careers .Filter-field .product-filter .accordion .accordion-body ul{
  padding: 6px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body .type-text,
.Careers .Filter-field  .product-filter .accordion .accordion-body .type-text {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body .type-text h5,
.Careers .Filter-field .product-filter .accordion .accordion-body .type-text h5{
  letter-spacing: 0px;
  color: #617088;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body .type-text a,
.Careers .Filter-field .product-filter .accordion .accordion-body .type-text a{
  letter-spacing: 0px;
  color: #095291;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body ul li,
.Careers .Filter-field .product-filter .accordion .accordion-body ul li{
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  align-items: center;
  margin: 12px 0;
  list-style: none;
  white-space: nowrap;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body ul li label,
.Careers .Filter-field .product-filter .accordion .accordion-body ul li label{
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-body ul li input[type="checkbox"],
.Careers .Filter-field .product-filter .accordion .accordion-body ul li input[type="checkbox"]{
  accent-color: #095291;
  height: 15px;
  width: 15px;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item:last-child .show {
  border-bottom: none;
}

.Careers .our_products-sec.career-filter .product-filter .accordion .accordion-item .accordion-header button[aria-expanded="true"] {
  color: #095291 !important;
}

.Careers .our_products-sec.career-filter .right-side-table {
  margin: 24px 0;
  padding: 15px 10px;
}

.Careers .our_products-sec.career-filter .right-side-table .gray-text {
  display: flex;
}

.Careers .our_products-sec.career-filter .right-side-table .gray-text p {
  letter-spacing: 0px;
  color: #8E8E8E;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}

.Careers .our_products-sec.career-filter .right-side-table table tr td {
  padding-left: 0;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  border-top: 1px solid #0E385A;
  border-bottom: 1px solid #0E385A;
  text-align: center;
  vertical-align: middle;
}

.Careers .our_products-sec.career-filter .right-side-table table tr td:first-child {
  text-align: left;
}

.Careers .our_products-sec.career-filter .right-side-table table tr td:last-child {
  text-align: right;
}

.Careers .our_products-sec.career-filter .right-side-table .loadmore-btn {
  background: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0;
  margin-top: 15px;
}

.Careers .our_products-sec.career-filter .m-view {
  display: none;
}

.Careers .our_products-sec.career-filter .m-view .filter.btn {
  background: #F8FAFC;
  padding: 8px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Careers .our_products-sec.career-filter .m-view .filter.btn img {
  margin-left: 8px;
}

.Careers .our_products-sec.career-filter .m-view .gray-text {
  display: flex;
  margin-top: 20px;
}

.Careers .our_products-sec.career-filter .m-view .gray-text p {
  letter-spacing: 0px;
  color: #8E8E8E;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}

.Careers .our_products-sec.career-filter .m-view .opportunities {
  margin-top: 10px;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 0;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs h4 {
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs .job-det {
  display: flex;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs .job-det p {
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  margin-right: 10px;
  width: 150px;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs .submit.btn {
  outline: none;
  background: none !important;
  box-shadow: none !important;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .jobs .submit.btn img {
  height: 16px;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .loadmore-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Careers .our_products-sec.career-filter .m-view .opportunities .loadmore-div .loadmore-btn {
  background: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0;
  margin-top: 25px;
}

.career-detail .our_products-sec.careerDetail {
  padding: 20px 80px 50px;
}
.career-detail .details {
  padding: 0 80px;
}

.career-detail .details .our_products-sec.careerDetail .sec-title {
  line-height: 38px;
}

.career-detail .details .our_products-sec.careerDetail .career-overview {
  margin-top: 15px;
}

.career-detail .details .our_products-sec.careerDetail .career-overview .job-section .news-time {
  margin-bottom: 15px;
}

.career-detail .details .our_products-sec.careerDetail .career-overview .job-section img {
  height: max-content;
  object-fit: contain;
}

.career-detail .details .our_products-sec.careerDetail .career-overview .job-section p,
.career-detail .details .our_products-sec.careerDetail .career-overview .job-section ul li {
  line-height: 34px;
  font-size: 15px;
  margin-top: 15px;
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  
}
.career-detail .details .career-left{
  overflow-y: scroll;
  height: 450px;
  overflow-x: hidden;
  padding-right: 30px;
  padding-bottom: 20px;
}
.career-detail .details .our_products-sec.careerDetail .location-div .social-icons {
  display: flex;
}
.career-detail .details .our_products-sec.careerDetail .location-div {
  margin-left: 40px;
  margin-top: 10px;
}
.career-detail .details .our_products-sec.careerDetail .location-div .address p{
  font-weight: 400 !important;
}
.career-detail .details .our_products-sec.careerDetail .location-div .address{
  margin-bottom: 40px;
}
.career-detail .details .our_products-sec.careerDetail .location-div .address h4{
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: left;
}
.career-detail .details .our_products-sec.careerDetail .location-div .social-icons a {
  background-color: transparent;
  padding: 8px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-decoration: none;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.career-detail .details .our_products-sec.careerDetail .location-div .social-icons a[title="Facebook"] img{
  height: 14px;
}
.career-detail .details .our_products-sec.careerDetail .location-div .social-icons a img {
  object-fit: contain;
  height: 20px;
  width: 100%;
  margin-bottom: 0;
}
.career-detail .details .our_products-sec.careerDetail .location-div .display-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.career-detail .details .our_products-sec.careerDetail .sec-bottom-title{
  font-size: 16px !important;
  font-weight: 600 !important;
}
.career-detail .details .our_products-sec.careerDetail .career-overview .job-section{
  border-bottom: 1px solid #D9D9D9;
  margin: 0 0 20px;
  padding: 0 0 10px;
}
.career-detail .details .our_products-sec.careerDetail .career-overview .job-section ul{
  padding-left: 15px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form label {
  letter-spacing: 0px;
  color: #00000082;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  height: 20px;
  padding: 0;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-transform: inherit;
}
.career-detail .details .our_products-sec.careerDetail .contact-form form{
  margin-top: 20px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .form-check input {
  height: 16px;
  width: 16px;
  accent-color: #26477F;
}

.career-detail .details .our_products-sec.careerDetail .contact-form .form-check label {
  margin-left: 10px;
  font-weight: 500;
  color: #000;
}
.career-detail .details .our_products-sec.careerDetail .contact-form  .PhoneInput .PhoneInputInput{
  border-bottom: 1px solid #000 !important;
}
.career-detail .details .our_products-sec.careerDetail .contact-form  .PhoneInput .PhoneInputInput::placeholder{
  letter-spacing: 0px;
  color: #00000082 !important;
  opacity: 1;
  font-size: 15px !important;
  font-weight: 400 !important;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .form-check label a{
  color: inherit;
  text-decoration: underline;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .form-check .form-error{
  margin-left: -20px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .submit-btn {
  background: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0;
  margin-top: 30px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .form-container {
  background-color: transparent;
  margin:20px 0;
}

.career-detail .details .our_products-sec.careerDetail .contact-form .upload-files-container {
  background-color: #25477107;
  width: 100%;
  padding: 30px 60px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 2px dashed #25477126; */
  height: 380px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2325477126' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.career-detail .details .our_products-sec.careerDetail .contact-form .upload-files-container {
  height: 210px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .drag-file-area {
  border-radius: 40px;
  margin: 36px 0 15px;
  /* padding: 30px 50px; */
  text-align: center;
}

.career-detail .details .our_products-sec.careerDetail .contact-form .drag-file-area .upload-icon {
  font-size: 50px;
}
.career-detail .details .contact-form .upload-files-container .uploaded-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.career-detail .details .contact-form .upload-files-container .uploaded-box .close-img{
  margin-left: -10px;
  background: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  border: none;
  margin-top: -42px;
  height: 22px;
  width: 22px;
  outline: none;
  box-shadow: none;
  right: 60px;
}
.career-detail .details .contact-form .upload-files-container .uploaded-box a{
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: #000;
  font-size: 14px;
  text-decoration: none;
}
.career-detail .details .contact-form .upload-files-container .uploaded-box a img{
  height: 14px;
  margin-right: 10px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .drag-file-area h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0;
  color: #000000;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .drag-file-area label {
  font-size: 15px;
  color: #7A818B;
}
.career-detail .details .our_products-sec.careerDetail .contact-form.drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}

.Mobile-request-quote .our_products-sec.request-quote .browse-files span,
.new-demo-modal .browse-files span {
  position: relative;
  top: -25px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .default-file-input {
  opacity: 0;
  width: 100%;
}

.career-detail .details .our_products-sec.careerDetail .contact-form .drag-file-area img {
  height: 30px;
}


.career-detail .details .our_products-sec.careerDetail .did-floating-input .css-13cymwt-control{
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-13cymwt-control .css-1u9des2-indicatorSeparator,
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-t3ipsp-control .css-1u9des2-indicatorSeparator{
  display: none;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-t3ipsp-control{
  border-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-1xc3v61-indicatorContainer svg{
  fill: #000;
  height: 18px;
  width: 18px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-1fdsijx-ValueContainer{
  padding-left: 0;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input .css-1dimb5e-singleValue{
  color: #323840;
}
.career-detail .details .our_products-sec.careerDetail .contact-form label.country{
  top: 12px;
  z-index: 999;
}
.career-detail .details .our_products-sec.careerDetail .contact-form label[value].country{
  top: -18px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input  #react-select-3-placeholder{
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #898989;
}
.career-detail .details .our_products-sec.careerDetail .contact-form{
  padding: 0 1px;
}
.career-detail .details .our_products-sec.careerDetail .contact-form .did-floating-input.css-b62m3t-container{
  z-index: 99;
}

.react-tel-input .special-label{
  display: block !important;
  left: 0px !important;
  top: -12px !important;
  color: #00000082;
  padding-left: 0 !important;
  z-index: 9 !important;
  background: transparent !important;
}
.react-tel-input .flag-dropdown{
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-tel-input .form-control{
  padding-left: 48px !important;
  height: 35px !important;
  width: 100% !important;
}
.react-tel-input .selected-flag{
  padding-left: 0 !important;
  border-bottom: 1px solid #000;
  border-radius: 0 !important;
}
.react-tel-input .form-control::placeholder{
  font-size: 14px;
  color: #000;
}

/*- Career page css ends-*/

/*- Certification page css-*/

.Certification .our_products-sec.certify {
  background-image: url(../images/career-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  align-items: end;
  display: flex;
}

.Certification .our_products-sec.certify .sec-title {
  color: #fff;
}

.Certification .our_products-sec.certification .certificates .certificate-box {
  width: 100%;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 15px;
}
.Certification .our_products-sec.certification .certificates .certificate-box a{
  text-align: left;
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
}
.Certification .our_products-sec.certification .sec-bottom-title{
  max-width: max-content !important;
  margin-bottom: 40px;
  text-align: justify;
}


/*- Certification page css ends-*/

/*- Login page css-*/


.login-page {
  background-image: url(../images/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.login-page .login-sec {
  background: #FFFFFF;
  opacity: 1;
  padding: 50px 50px 15px;
  width: 75%;
}

.login-page .login-sec .left-side h1 {
  font-size: 45px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 350px;
}

.login-page .login-sec .left-side p {
  letter-spacing: 0.22px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  width: 260px;
  margin-bottom: 20px;
}

.login-page .login-sec .login-form {
  margin-top: 84px;
  padding: 0 20px;
}

.login-page .login-sec .login-form .did-floating-label {
  color: #81868C;
  font-size: 14px;
}

.login-page .login-sec .login-form .did-floating-input {
  color: #000000;
  font-size: 15px;
  border-bottom: 1px solid #81868C;
}

.login-page .login-sec .login-form .did-floating-input:focus {
  border-bottom: 1px solid #000000;
}

.login-page .login-sec .login-form .display-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.login-page .login-sec .login-form .signedin {
  display: flex;
  justify-content: space-between;
}

.login-page .login-sec .login-form .signedin .form-check {
  margin-right: 20px;
}

.login-page .login-sec .login-form .signedin .form-check input {
  width: 15px;
  height: 15px;
  accent-color: #0B2A60;
  margin-top: 0.1rem;
}

.login-page .login-sec .login-form .signedin .form-check .form-check-label {
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.login-page .login-sec .login-form .signedin a {
  letter-spacing: 0px;
  color: #909090;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
}

.login-page .login-sec .login-form .register-link {
  letter-spacing: 0px;
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}

.login-page .login-sec .login-form .register-link a {
  color: #000 !important;
  text-decoration: underline;
  font-weight: 500;
  margin-left: 4px;
}

.login-page.new-register .login-form {
  margin-top: -24px;
}

.login-page.new-register {
  height: 97vh;
}

/*- Login page css ends-*/

/*-News & events css-*/

.news-and-events .our_products-sec.latestNews {
  padding: 20px 80px 50px;
}

.news-and-events .our_products-sec.latestNews .latest-news-sec .news-section {
  margin-bottom: 45px;
}

.news-and-events .our_products-sec.latestNews .latest-news-sec .viewmore-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-and-events .our_products-sec.latestNews .latest-news-sec a {
  text-decoration: none;
  color: inherit;
}

.news-and-events .details {
  padding: 0 220px;
}

.news-and-events .details .our_products-sec.latestNews .sec-title {
  /* width: 550px; */
  line-height: 42px;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec {
  margin-top: 15px;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .news-time {
  margin-bottom: 15px;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section img {
  /* height: max-content;
  object-fit: contain; */
  height: 340px;
  object-fit: cover;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section p:not(.news-time) {
  line-height: 34px;
  font-size: 15px;
  margin-top: 15px;
}
.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .news-certify img{
  height: 80px;
}
.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .news-certify{
  /* float: right; */
  position: absolute;
  z-index: 9;
  display: block;
  bottom: 57%;
  left: 100px;
}
.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section p:not(.news-time) span{
  font-weight: 600;
}
.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .social-icons {
  display: flex;
  margin: 25px 0;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .social-icons a {
  background-color: #F3F6FA;
  padding: 8px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-decoration: none;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-and-events .details .our_products-sec.latestNews .latest-news-sec .news-section .social-icons a img {
  object-fit: contain;
  height: 12px;
  width: 100%;
  margin-bottom: 0;
}

.news-and-events .our_products-sec.relatedNews {
  padding: 50px 80px;
}

.news-and-events .our_products-sec.relatedNews .latest-news-sec {
  margin-top: 20px;
}

.news-and-events .our_products-sec.relatedNews {
  background: transparent linear-gradient(132deg, #e9ddee42 0%, #e0eef682 100%) 0% 0% no-repeat;
}

.news-and-events .our_products-sec.relatedNews .latest-news-sec .news-section h5 {
  font-size: 17px;
  font-weight: 400;
}
.news-and-events .our_products-sec.relatedNews .latest-news-sec .news-section img{
  height: 177px;
}
.news-and-events .bottom-more {
  display: none;
}

.bottom-more .button-more {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-more .button-more a {
  background: transparent;
  padding: 15px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #000;
  border-radius: 0;
  height: 45px;
  width: 150px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.bottom-more .button-more a img {
  height: 14px;
}

/*-News & events css ends-*/

/*-Policies css-*/

#CookiePolicyModal .modal-content {
  border-radius: unset !important;
  background-color: #fff;
  width: 100% !important;
  bottom: 0;
  max-width: 100%;
  left: 50%;
  -webkit-transform: translate(-50%) !important;
  transform: translate(-50%) !important;
  position: fixed;
  border: none;
}

#CookiePolicyModal .modal-content .modal-header .modal-title {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #000;
  opacity: 1;
  letter-spacing: 0px;
  text-transform: inherit;
}

#CookiePolicyModal .modal-content .modal-header {
  border: none;
}

#CookiePolicyModal .modal-content .modal-body {
  padding-top: 0;
}

#CookiePolicyModal .modal-content .modal-body p {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  letter-spacing: 0px;
  opacity: 1;
  color: #000;
  line-height: 28px;
}

#CookiePolicyModal .modal-body .accept-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #18A6EF;
  border-radius: 0;
  height: 45px;
  width: 150px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 15px;
}

#CookiePolicyModal .modal-content .modal-header .btn-close {
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
}

#CookiePolicyModal .modal-content .modal-body p a {
  text-decoration: underline;
}

#CookiePolicyModal .modal-content .modal-body .bottom-more .button-more a {
  width: max-content;
}

.policy-sec .our_products-sec.policy {
  padding: 50px 220px;
}

.policy-sec .our_products-sec.policy .sec-title {
  margin-bottom: 20px;
}

.policy-sec .our_products-sec.policy p {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  text-align: left;
  margin-bottom: 28px;
  text-align: justify;
}

.policy-sec .our_products-sec.policy .policy-title {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
  letter-spacing: 0px;
  color: #0B2A60;
  margin: 35px 0 28px;
  line-height: 34px;
}

.policy-sec .our_products-sec.policy .sub-title {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  opacity: 1;
  letter-spacing: 0px;
  color: #000000;
}

.policy-sec .our_products-sec.policy .policy-sub a {
  color: #3366BE;
  text-decoration-line: underline;
}

.policy-sec .our_products-sec.policy .policy-sub {
  border-bottom: 1px solid #D9D9D9;
}

.policy-sec .our_products-sec.policy .policy-sub:last-child {
  border-bottom: none;
}

.policy-sec .our_products-sec.policy ul {
  padding-left: 15px;
}

.policy-sec .our_products-sec.policy ul li {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
}


/*-Policies css ends-*/

.gradient {
  position: relative;
  border: none;
  width: 100%;
  max-width: 1600px;
  margin: -305px auto;
  height: 310px;
  background: linear-gradient(0deg,
      #030f1f 0,
      rgba(3, 15, 31, 0.987) 4.3%,
      rgba(3, 15, 31, 0.951) 9.2%,
      rgba(3, 15, 31, 0.896) 14.6%,
      rgba(3, 15, 31, 0.825) 20.5%,
      rgba(3, 15, 31, 0.741) 26.8%,
      rgba(3, 15, 31, 0.648) 33.5%,
      rgba(3, 15, 31, 0.55) 40.4%,
      rgba(3, 15, 31, 0.45) 47.6%,
      rgba(3, 15, 31, 0.352) 55%,
      rgba(3, 15, 31, 0.259) 62.5%,
      rgba(3, 15, 31, 0.175) 70.1%,
      rgba(3, 15, 31, 0.104) 77.7%,
      rgba(3, 15, 31, 0.049) 85.2%,
      rgba(3, 15, 31, 0.013) 92.7%,
      rgba(3, 15, 31, 0));
}

.gradient::after {
  background: linear-gradient(0deg,
      #030f1f 0,
      rgba(3, 15, 31, 0.987) 4.3%,
      rgba(3, 15, 31, 0.951) 9.2%,
      rgba(3, 15, 31, 0.896) 14.6%,
      rgba(3, 15, 31, 0.825) 20.5%,
      rgba(3, 15, 31, 0.741) 26.8%,
      rgba(3, 15, 31, 0.648) 33.5%,
      rgba(3, 15, 31, 0.55) 40.4%,
      rgba(3, 15, 31, 0.45) 47.6%,
      rgba(3, 15, 31, 0.352) 55%,
      rgba(3, 15, 31, 0.259) 62.5%,
      rgba(3, 15, 31, 0.175) 70.1%,
      rgba(3, 15, 31, 0.104) 77.7%,
      rgba(3, 15, 31, 0.049) 85.2%,
      rgba(3, 15, 31, 0.013) 92.7%,
      rgba(3, 15, 31, 0));
}

/*Card*/


.card {
  border: none;
  background-color: inherit;
  z-index: 50;
  margin-top: 255px;
}

.rent {
  margin-top: 50px;
}

.card h1 {
  font-size: 1.875rem;
  color: white;
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-left: 35px;
}

.card section {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1140px;
}

.card-wrapper {
  width: 100%;
  max-width: 180px;
  padding-bottom: 1rem;
}

.card-wrapper p {
  overflow: hidden;
}

.movieTitle {
  margin-top: 10px;
  max-height: 44px;
  color: white;
}

.cardimg {
  width: 100%;
  max-width: 160px;
  min-width: 111px;
  height: 100%;
  height: 228px;
  min-height: 158px;
}

/*---*/

.button-wrapper {
  display: flex;
}



.button {
  margin: auto;
  justify-content: center;
}

.success-message {
  color: green;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.form-error {
  color: #d32f2f !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}
.not-available {
  color: #d32f2f !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}


/* .btn {
  background-color: rgba(40, 115, 197, 0.8);
  font-size: 1.125rem;
  width: 185px;
  margin: auto auto 60px auto;
} */

.button-wrapper a {
  cursor: pointer;
  color: #78b6fa !important;
}

.button-wrapper a svg {
  border: 1px solid #78b6fa;
  border-radius: 50%;
}

.button-wrapper a:hover {
  color: #f4ce24 !important;
}

.buybutton {
  background-color: rgba(40, 115, 197, 0.8);
  font-size: 1.125rem;
  width: 185px;
  margin: auto auto 60px 95px;
}

.nobutton {
  margin-left: auto;
}

/*Letters*/
.letter-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.letter {
  width: 3.7%;
  margin: 5px 0;
  text-align: center;
}

.letter a {
  color: #78b6fa !important;
  display: block;
  padding: 5px 0;
  margin: 0 1px;
  background-color: rgba(120, 182, 250, 0.05);
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hidden-download a::after {
  display: none !important

}

.product-detail-carousel {
  position: relative;
  overflow: hidden;
}

.carousel-container {
  position: relative;
  z-index: 1;
}

.zoom-container {
  position: relative;
  background: none; /* Hide the background image */
}

.zoom-image {
  background: none; /* Hide the background image on the zoomed image */
}



