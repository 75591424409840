.footer-sec{
  background: #0B2A60;
  /* background-image: url(../images/blue-design.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 38%;
  background-position-x: 236px; */
}
.footer-wrapper{
  z-index: 2;
}
.footer-sec .footer-wrapper .footer-desc {
  margin-bottom: 40px;
  padding-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #f0f0f02b;
}
.footer-sec .footer-wrapper .footer-desc  h2{
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 26px;
  font-weight: 300;
  line-height: 38px;
}
.footer-sec .footer-wrapper .footer-desc .display-btn{
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  margin-right: 12%;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.footer-sec .footer-wrapper .footer-desc .display-btn a{
  color: inherit;
  text-decoration: none;
}
.footer-sec .footer-wrapper .footer-desc .display-btn img{
  height: 14px;
}
.bottom-footer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.app-footer {
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 2;
}

.appText {
  color: white;
  font-size: 1rem;
}

.gplayimg {
  margin-left: 30px;
}

.footer-sec .footer-wrapper .toplink {
  /* margin: auto; */
  /* border-top: 1px solid hsla(0, 0%, 100%, 0.1); */
  z-index: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.footer-sec .footer-wrapper .mainfooter {
  padding: 50px 80px;
}
.toplink a {
  color: white;
}

.footernav {
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
  padding: 0px;
}

.footer-sec .footer-wrapper .footerlink {
  display: inline-block;
  margin: 6px 20px;
}
.footer-sec .footer-wrapper .products .footerlink{
  line-height: 25px;
}
.footer-sec .footer-wrapper .footerlink.phno{
  margin: 0px 20px;
  text-transform: lowercase;
}
.footer-sec .footer-wrapper .footerlink.address{
  margin-bottom: 20px;
}
.footer-sec .footer-wrapper .footerlink.title{
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 400;
  font-size: 20px;
}
.footer-sec .footer-wrapper .footerlink a{
  letter-spacing: 0px;
  color: #DAE6FA;
  text-transform: inherit;
  opacity: 1;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  text-decoration: none;
}
.footer-sec .footer-wrapper .footerlink.address a{
  line-height: 33px;
}
.footer-sec .footer-wrapper .footerlink a:hover {
  color: #b8c2d3;
}
.footer-sec .footer-wrapper .logo-wrapper{
  margin: 5px 0;
}
.footer-sec .footer-wrapper .logo-wrapper a img{
  height: 50px;
  margin: 6px 0;
 }
.follow-us-sec{
  /* margin-left: -132px; */
  margin-top: 100px;
}
.follow-us-sec .follow-us {
  margin: 0;
  padding: 0;
  display: flex;
}
.follow-us-sec .follow-us .followlink{
  margin-right: 10px;
  list-style: none;
}
.follow-us-sec .follow-us .followlink a img{
  height: 24px;
}
.follow-us-sec .followtitle{
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.8;
  font-size: 15px;
  font-weight: 400;
}
.region {
  margin-top: 5px;
  width: 100%;
  justify-content: center;
}

.regionnav {
  font-size: 13px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.regionnav span {
  color: white;
  padding-right: 5px;
}

.regionlink {
  margin-left: 0px;
  margin-right: 2px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.89;
}

.regionlink a {
  cursor: pointer;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.89;
  font-size: 12px;
  text-decoration: none;
  font-weight: 100;
}

.regionlink a:hover {
  color: #b8c2d3;
}

.copyright {
  width: 66%;
  max-width: 1090px;
}

.copyright span {
  margin: auto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.38;
  font-size: 12px;
  font-weight: 100;
}
.designedby a{
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.38;
  font-size: 12px;
  font-weight: 100;
}
.copyrightnav {
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}
.mobile-footer{
  margin-top: 40px;
  display: none;
}
.mobile-footer .list-items .accordion .accordion-item .accordion-header button{
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 400;
  font-size: 24px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.mobile-footer .list-items .accordion .accordion-body{
  display: grid;
}
.mobile-footer .list-items .accordion .accordion-body .footerlink{
  margin: 6px 6px !important;
}

.mobile-footer .list-items .accordion .accordion-button:not(.collapsed)::after {
  content: "-";
  font-size: 50px;
}
.mobile-footer .list-items .accordion .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  font-size: 30px;
  line-height: 14px;
}
.mobile-footer .list-items .accordion .accordion-item {
  padding: 15px 0px;
  border-bottom: 1px solid #f0f0f030;
}
.mobile-footer .list-items .accordion .accordion-body .footerlink a{
  font-size: 18px;
}
.mobile-footer .list-items {
  margin-top: 22px;
}
.mobile-footer .footernav{
  margin-top: 24px;
  border-bottom: 1px solid #f0f0f030;
  padding-bottom: 40px;
}
.mobile-footer .footernav .footerlink{
  margin: 6px 6px !important;
}
.mobile-footer .footernav .footerlink.title{
  font-size: 24px;
}
.mobile-footer .footernav .footerlink a {
  font-size: 18px;
}
.mobile-footer  .follow-us-sec{
  margin-top: 50px;
}
.mobile-footer .follow-us-sec .followtitle{
  font-size: 20px;
}
.mobile-footer .follow-us-sec .follow-us .followlink a img {
  height: 38px;
}


@media (max-width: 600px) {
  .toplink {
    margin-left: 0px;
    width: 100%;
  }

  .footerlink {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .footernav {
    flex-direction: column;
  }

  .regionnav {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center !important;
  }

  .regionlink {
    justify-content: center;
    margin: auto;
  }

  .copyrightlink {
    justify-content: center;
    margin: auto;
  }
}
